import { Tooltip } from "antd";
import clsx from "clsx";
import { useState } from "react";
import { FaCheck, FaCopy } from "react-icons/fa";

export const DynamicTitle = ({
  title,
  itemId,
  itemUuid,
  subItemUuid,
  isParent,
}: {
  title?: string;
  itemId?: string;
  itemUuid?: string;
  subItemUuid?: string;
  isParent?: boolean;
}) => {
  const [copied, setCopied] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${
        window.location.pathname
      }?ticketId=${itemUuid}${subItemUuid ? `&subTicketId=${subItemUuid}` : ""}`
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      className={clsx("is-flex is-align-items-center", {
        "pr-3": !isParent,
      })}
      onMouseEnter={() => !isParent && setIsHovering(true)}
      onMouseLeave={() => !isParent && setIsHovering(false)}
    >
      <Tooltip title={title} placement="top" zIndex={999999999}>
        <h2 className="title is-5 mb-0 is-clickable hover-underlined">
          {itemId}
        </h2>
      </Tooltip>

      {isHovering && (
        <Tooltip
          title={copied ? "Copied!" : "Copy"}
          placement="top"
          zIndex={999999999}
        >
          <button
            className="ml-2 button is-small is-rounded is-ghost is-no-box-shadow is-clickable"
            onClick={handleCopy}
            aria-label="Copy issue key"
            style={{
              height: 16,
              padding: 0,
            }}
          >
            {copied ? (
              <FaCheck size={14} className="has-text-success" />
            ) : (
              <FaCopy size={14} className="has-text-grey" />
            )}
          </button>
        </Tooltip>
      )}
    </div>
  );
};
