import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FallbackImage } from "../../../components";
import styles from "./ChatInfo.module.scss";
import {
  EdnaBotId,
  EdnaChatInfo,
  EdnaChatType,
} from "../../../models/EdnaChat";
import { useStore } from "../../../stores";
import locale from "../../../constants/locale";
import { UserType } from "../../../models";
import { useCallback } from "react";
import { toast } from "bulma-toast";
import UpdateProfile from "./UpdateProfile";
import DeleteHubModal from "./hubs/DeleteHubModal";
import LeaveHubModal from "./hubs/LeaveHubModal";
import HubInfo from "./hubs/HubInfo";
import EditHubModal from "./hubs/EditHubModal";

const GroupInfo = observer(({ groupId }: { groupId: number }) => {
  const { t } = useTranslation();
  const { users, getAssignedGroupIdsOfUser } = useStore("users");

  if (!groupId) return null;

  const members =
    users?.filter((user) => {
      if (user.id === EdnaBotId) return false;
      if ([UserType.Root, UserType.Administrator].includes(user?.type!)) {
        return true;
      }
      return getAssignedGroupIdsOfUser(user, 0).includes(groupId);
    }) || [];

  return (
    <>
      <div className="has-border-bottom pb-4">
        <h2 className="title is-6 mb-1">{t(locale.members)}</h2>
        <div>
          {members.map((member) => (
            <span
              key={member.id}
              className="tag is-primary is-medium mr-3 my-2 is-clickable"
            >
              {member.name}
            </span>
          ))}
        </div>
      </div>
    </>
  );
});

const UserInfo = observer(({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const { users, liveChatCards } = useStore("users");

  const user = users?.find((u) => u.id === userId);

  if (!user || !selectedWorkspace) return null;

  const liveCards =
    liveChatCards?.filter((card) => {
      if ([UserType.Root, UserType.Administrator].includes(user?.type!)) {
        return true;
      }
      if (user) {
        const liveChatFilters =
          user?.permissions?.perWorkspace?.[selectedWorkspace.id]
            ?.perFunction?.["op"]?.liveChatFilters || {};

        const keysWithNonEmptyValues = Object.keys(liveChatFilters).filter(
          (key) => liveChatFilters[key].length > 0
        );
        return keysWithNonEmptyValues.map((m) => parseInt(m)).includes(card.id);
      }
      return false;
    }) ?? [];

  return (
    <>
      <div className="has-border-bottom pb-4">
        <h2 className="title is-6 mb-1">{t(locale.teams)}</h2>
        <div>
          {liveCards.map((card) => (
            <span
              key={card.id}
              className="tag is-primary is-medium mr-3 my-2 is-clickable"
            >
              {card.name}
            </span>
          ))}
        </div>
      </div>

      <div className="has-border-bottom mt-4 pb-4">
        <h2 className="title is-6 mb-3">{t(locale.contactInformation)}</h2>

        <div className="is-flex">
          <button className="button mr-4">
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </span>
          </button>
          <div>
            <p className="subtitle is-6 mb-0">{t(locale.email)}</p>
            <a
              href={"mailto:" + user.email}
              target="_blank"
              rel="noreferrer"
              className="subtitle is-7 has-text-primary"
            >
              {user.email}
            </a>
          </div>
        </div>
      </div>

      {/* <div className="mt-4">
        <h2 className="title is-6 mb-4">{t(locale.aboutMe)}</h2>

        <div>
          <h4 className="subtitle has-text-grey mb-0" style={{ fontSize: 12, lineHeight: "4px" }}>Start Date</h4>
          <span className="subtitle is-7 has-text-primary">Nov 21</span>
        </div>
      </div> */}
    </>
  );
});

const ChatInfo = observer(
  ({ chatInfo, onClose }: { chatInfo?: EdnaChatInfo; onClose: () => void }) => {
    const { t } = useTranslation();
    const { user, userHasAdminAccess } = useStore("auth");
    const { selectedWorkspace } = useStore("workspaces");
    const { selectedChat } = useStore("ednaChats");
    const { selectedHub } = useStore("hubs");

    const copyEdnaBotId = useCallback(async () => {
      if (!selectedWorkspace?.id || !user?.id || !selectedChat?.id) {
        return;
      }
      try {
        await navigator.clipboard.writeText(
          `${selectedWorkspace.id}|${user.id}|${selectedChat.id}`
        );
        toast({
          message: t(locale.ednaBotIdCopied),
          position: "top-right",
          dismissible: true,
          type: "is-success",
          pauseOnHover: true,
        });
      } catch (error) {
        if (error instanceof Error) {
          toast({
            message: error.message,
            position: "top-right",
            dismissible: true,
            type: "is-danger",
            pauseOnHover: true,
          });
        }
        console.log(error);
      }
    }, [selectedWorkspace?.id, user?.id, selectedChat?.id, t]);

    return (
      <section
        className={clsx("p-5 full-screen-mobile is-active", styles.container)}
      >
        <div
          className={clsx(
            "is-flex has-border-bottom is-align-items-center px-4 mb-3 is-hidden-desktop",
            styles.header
          )}
        >
          <div className="is-flex is-flex-grow-1 is-justify-content-flex-end">
            <span className="icon is-small is-clickable" onClick={onClose}>
              <i className="fas fa-times"></i>
            </span>
          </div>
        </div>

        {chatInfo?.profileUrl && (
          <div className="is-flex is-justify-content-center">
            <figure className="image is-96x96 is-align-self-center">
              <FallbackImage
                className="is-rounded"
                src={chatInfo.profileUrl}
                fallback="/assets/user.png"
                alt={""}
              />
              {userHasAdminAccess && chatInfo.type === EdnaChatType.CHANNEL && (
                <UpdateProfile chat={selectedChat} />
              )}
            </figure>
          </div>
        )}

        <div className="is-flex is-justify-content-center mt-3 mb-3">
          <h1
            className={clsx(
              "title is-4 is-align-self-center has-text has-text-centered",
              {
                "ml-5": chatInfo?.type === EdnaChatType.BOT,
              }
            )}
          >
            {chatInfo?.name}
          </h1>

          {selectedHub && chatInfo?.type === EdnaChatType.HUB && (
            <EditHubModal hub={selectedHub} />
          )}

          {chatInfo?.type === EdnaChatType.BOT && (
            <button
              onClick={() => copyEdnaBotId()}
              style={{ height: "1.5rem" }}
              className="button is-ghost is-no-box-shadow "
            >
              <span className="icon" style={{ position: "absolute" }}>
                <img src="/assets/copy.svg" alt="download" />
              </span>
            </button>
          )}
        </div>

        {selectedHub && selectedHub?.creatorId === user?.id && (
          <DeleteHubModal hub={selectedHub} />
        )}

        {selectedHub && selectedHub?.memberIds?.includes(user?.id!) && (
          <LeaveHubModal hub={selectedHub} />
        )}

        {chatInfo?.type === EdnaChatType.SINGLE && (
          <UserInfo userId={String(chatInfo.id)} />
        )}

        {chatInfo?.type === EdnaChatType.CHANNEL && (
          <GroupInfo groupId={parseInt(String(chatInfo.id))} />
        )}

        {selectedHub && <HubInfo hub={selectedHub} />}
      </section>
    );
  }
);

export default ChatInfo;
