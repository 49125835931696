import React, { useState } from "react";
import { Hub } from "../../../../models";
import HubModal from "../../../../pages/teammates/components/hubs/HubModal";

export default function EditHubModal({ hub }: { hub: Hub }) {
  const [showHubModal, setShowHubModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowHubModal(true)}
        style={{ height: "1.5rem" }}
        className="button is-ghost is-no-box-shadow "
      >
        <span className="icon" style={{ position: "absolute" }}>
          <img src="/assets/edit.svg" alt="edit" />
        </span>
      </button>
      {showHubModal && (
        <HubModal open onClose={() => setShowHubModal(false)} hub={hub} />
      )}
    </>
  );
}
