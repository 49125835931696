import { observer } from "mobx-react-lite";
import { BoardItemCard, BoardItemComment } from "../../../../models";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../stores";
import { DateTime } from "luxon";
import { useRef, useState, useCallback, useEffect } from "react";
import locale from "../../../../constants/locale";
import Mentionify from "../../../../components/mentionify/Mentionify";
import { FallbackImage, Loader } from "../../../../components";
import Mentions from "../../../../components/mentions/Mentions";
import { useHistory, useLocation } from "react-router";

const Comments = ({ boardItem }: { boardItem: BoardItemCard }) => {
  const { t } = useTranslation();
  const auth = useStore("auth");
  const [loading, setLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [comments, setComments] = useState<BoardItemComment[]>([]);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramCommentId = queryParams.get("commentId");
  const [selectedComment, setSelectedComment] = useState<
    BoardItemComment | undefined
  >();
  const [messageValue, setMessageValue] = useState("");
  const { users } = useStore("users");
  const { loadComments, deleteComment, updateComment, createComment } =
    useStore("boards");

  const listener = useCallback(
    async (e: any) => {
      if (e.keyCode !== 13) {
        return false;
      }
      e.preventDefault();

      if (e.altKey || e.ctrlKey) {
        const { selectionStart, selectionEnd } = e.target;
        const text = (e.currentTarget as any).value ?? "";
        const newValue =
          text.substring(0, selectionStart) +
          "\n" +
          text.substring(selectionEnd);

        setMessageValue(newValue);
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
        }, 0);
        e.preventDefault();
      } else {
        setSendingMessage(true);
        try {
          if (selectedComment) {
            const comment = await updateComment(boardItem, selectedComment.id, {
              userId: auth?.user?.id || "",
              comment: messageValue,
              meta: selectedComment.meta,
            } as any);
            if (comment) {
              setComments((prev) =>
                prev.map((f) => (f.id === selectedComment.id ? comment : f))
              );
            }
          } else {
            const comment = await createComment(boardItem, {
              userId: auth?.user?.id || "",
              comment: messageValue,
            } as any);
            if (comment) {
              setComments((prev) => [...prev, comment]);
            }
          }
          setSelectedComment(undefined);
          setMessageValue("");
        } catch (error) {
          console.log(error);
        } finally {
          setSendingMessage(false);
        }
      }
    },
    [
      auth?.user?.id,
      boardItem,
      createComment,
      messageValue,
      selectedComment,
      updateComment,
    ]
  );

  const renderUserSuggestion = useCallback((suggestion: any) => {
    return <div>{suggestion.display}</div>;
  }, []);

  const displayTransformHandler = useCallback((_: any, display: any) => {
    return "@" + display;
  }, []);

  const onDelete = useCallback(
    async (id: number) => {
      try {
        await deleteComment(boardItem.uuid, id);
        setComments((prev) => prev.filter((f) => f.id !== id));
      } catch (error) {
        console.error(error);
      }
    },
    [boardItem.uuid, deleteComment]
  );

  const load = useCallback(async () => {
    setLoading(true);
    const comments: BoardItemComment[] = await loadComments(boardItem.uuid);
    setComments(comments);
    setLoading(false);
    const comment = comments.find((f) => f.meta?.refId === paramCommentId);
    if (comment) {
      setTimeout(() => {
        document.getElementById("item-comment-" + comment.id)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        const { pathname } = location;
        history.push(pathname);
      }, 200);
    }
  }, [boardItem.uuid, history, loadComments, location, paramCommentId]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div className="is-relative">
      <div className="has-border mb-4" id="comment-box">
        <Mentions
          refMention={textAreaRef}
          disabled={sendingMessage}
          value={messageValue}
          onChange={(e: any) => {
            setMessageValue(e.target.value);
          }}
          trigger="@"
          data={users
            ?.filter((f) => f.id !== auth?.user?.id)
            ?.map((user: any) => ({
              id: user.id,
              display: user.name,
            }))}
          onKeyDown={listener}
          renderSuggestion={renderUserSuggestion}
          displayTransform={displayTransformHandler}
          markup={"@[__display__](__id__)"}
          placeholder={t(locale.addAComment)}
          appendSpaceOnAdd={true}
        />
      </div>
      <div className="is-flex is-flex-direction-column is-flex-direction-column-reverse">
        {comments?.map((comment) => {
          const user = users?.find((f) => f.id === comment.userId);
          const date = comment.createdAt;
          const time = date.hasSame(DateTime.utc(), "day")
            ? date.toFormat("t")
            : date.toLocaleString({
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
              });
          return (
            <div
              key={comment.id}
              id={"item-comment-" + comment.id}
              className="is-flex"
            >
              <figure className="is-flex-shrink-0">
                <p className="image is-40x40 is-square">
                  <FallbackImage
                    className="is-rounded"
                    src={user?.image}
                    fallback="/assets/avatar.svg"
                    alt={user?.name || "user"}
                  />
                </p>
              </figure>
              <div className="ml-3 is-flex is-flex-direction-column">
                <div className="is-flex">
                  <strong className="mr-2">{user?.name || "User"}</strong>
                  <span>{time}</span>
                </div>
                <div
                  className={comment.userId !== auth.user?.id ? "mb-5" : ""}
                  style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                >
                  <Mentionify>{comment.comment}</Mentionify>
                </div>
                {comment.userId === auth.user?.id && (
                  <div className="is-flex">
                    <button
                      className="button is-ghost p-0 is-no-box-shadow"
                      type="button"
                      onClick={() => {
                        setSelectedComment(comment);
                        setMessageValue(comment.comment);
                        document.getElementById("comment-box")?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }}
                    >
                      {t(locale.edit)}
                    </button>
                    <button
                      className="button is-ghost p-0 ml-2 is-no-box-shadow"
                      type="button"
                      onClick={() => onDelete(comment.id)}
                    >
                      {t(locale.delete)}
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {loading && (
        <div className="loaderClass">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default observer(Comments);
