import { makeAutoObservable } from "mobx";
import {
  Campaign,
  CampaignDataType,
  ChatChannel,
  ContentType,
  Message,
  WhatsappTemplate,
  WhatsappTemplateActionButtonType,
  WhatsappTemplateStatus,
} from "../models";
import firebase from "firebase/compat/app";
import {
  deleteTemplateFromTwilio,
  getCampaignResults,
  getCampaigns,
  getCampaignStats,
  postCampaign,
  postCampaignTemplateNotification,
  postTemplateStatusTwilio,
  postTemplateToMetaWhatsapp,
  postTemplateToTwilio,
  postTemplateToTwilioForApproval,
} from "../services/chathub";

import auth from "./auth";
import bots from "./bots";
import audit from "./audit";

type Paginate = {
  data: Campaign[];
  total: number;
  last_page: number;
  current_page: number;
};

const DefaultPaginate: Paginate = {
  data: [],
  total: 0,
  last_page: 0,
  current_page: 0,
};

type CampaignStats = {
  sent: number;
  delivered: number;
  read: number;
  deliveryRate: number;
  readRate: Number;
};

class CampaignsStore {
  private db: firebase.firestore.Firestore;
  campaignStats: CampaignStats = {
    sent: 0,
    delivered: 0,
    read: 0,
    deliveryRate: 0,
    readRate: 0,
  };
  campaigns: Paginate = DefaultPaginate;
  results: Message[] = [];
  selectedCampaign?: Campaign;
  whatsappTemplates: WhatsappTemplate[] = [];

  constructor() {
    makeAutoObservable(this);
    this.db = firebase.firestore();
  }

  loadCampaigns = async (page?: number) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    const campaigns = await getCampaigns(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      page
    );

    this.setCampaigns(campaigns);

    // this.setSelectedCampaign(
    //   campaigns?.find((f: Campaign) => f.id === this.selectedCampaign?.id) ||
    //     this.selectedCampaign
    // );
  };

  loadCampaignStats = async () => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    const stats = await getCampaignStats(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password
    );

    this.campaignStats = stats;
  };

  loadResults = async (campaignId: string, pageSize?: number) => {
    if (!bots.selectedBot) {
      return;
    }

    const response = await getCampaignResults(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      Number(campaignId),
      pageSize
    );

    if (response) {
      this.setSelectedCampaign(response.campaign);
      this.setResults(response.messages);
    }
  };

  createCampaign = async (
    name: string,
    channel: ChatChannel,
    data: string,
    campaignDataType: CampaignDataType,
    file?: File,
    scheduleFor?: string
  ) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    const campaign = await postCampaign(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      name,
      channel,
      data,
      campaignDataType,
      file,
      scheduleFor
    );

    if (campaign) {
      this.setSelectedCampaign(campaign);
      // this.setCampaigns([campaign, ...this.campaigns]);

      audit.logEvent("campaign_created", {
        id: campaign.id,
        doc: campaign,
        botId: bots.selectedBot.id,
      });
    }
  };

  notifyAdminForWATemplate = async (
    userName: string,
    templateId: string,
    type: "created" | "deleted"
  ) => {
    if (!bots.selectedBot || !auth.user) {
      return;
    }

    await postCampaignTemplateNotification(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      userName,
      templateId,
      type
    );
  };

  loadMessageTemplates = async () => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      let query = this.db
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("whatsappTemplates");

      const snapshot = await query.orderBy("createdAt", "desc").get();

      const data = snapshot.docs
        .map((doc) => {
          const obj = doc.data();
          obj.id = doc.id;
          return obj;
        })
        .filter((f) => !f.deletedAt) as WhatsappTemplate[];

      this.whatsappTemplates = data;
    } catch (error) {
      console.log(error);
    }
  };

  convertCtaPayload = (actions?: any[]) => {
    return actions?.map((action) => ({
      type:
        action.type === WhatsappTemplateActionButtonType.PHONE_NUMBER
          ? "PHONE_NUMBER"
          : "URL",
      title: action.text,
      [action.type === WhatsappTemplateActionButtonType.PHONE_NUMBER
        ? "phone"
        : "url"]: action.value,
    }));
  };

  createTwilioContentTemplate = async (template: WhatsappTemplate) => {
    let types = {};
    if (template.contentType === ContentType.TEXT) {
      types = {
        "twilio/text": {
          body: template.messageBody,
        },
      };
    } else if (template.contentType === ContentType.MEDIA) {
      types = {
        "twilio/media": {
          body: template.messageBody,
          media: [template.meta.media],
        },
      };
    } else if (template.contentType === ContentType.QUICK_REPLY) {
      types = {
        "twilio/quick-reply": {
          body: template.messageBody,
          actions: template.meta.actions,
        },
      };
    } else if (template.contentType === ContentType.CTA) {
      types = {
        "twilio/call-to-action": {
          body: template.messageBody,
          actions: this.convertCtaPayload(template.meta.actions),
        },
      };
    } else if (template.contentType === ContentType.LIST_PICKER) {
      types = {
        "twilio/list-picker": {
          body: template.messageBody,
          button: template.meta.button,
          items: template.meta.items,
        },
      };
    } else if (template.contentType === ContentType.CARD) {
      types = {
        "twilio/card": {
          title: template.messageBody,
          subtitle: template.meta?.button,
          media: template.meta?.media ? [template.meta.media] : undefined,
          actions:
            template?.meta?.buttonType === "call-to-action"
              ? this.convertCtaPayload(template.meta.actions)
              : template?.meta?.actions.map((action: any) => ({
                  type: "QUICK_REPLY",
                  title: action.title,
                  id: action.id,
                })),
        },
      };
    }

    const payload = {
      friendly_name: template.name,
      language: template.messageLanguage,
      variables: {},
      types: types,
    };

    if (template?.fieldsMapping?.length) {
      payload.variables = template.fieldsMapping.reduce((acc, item) => {
        let key = item.source.replace("{{", "").replace("}}", "");
        acc[key] = item.value;
        return acc;
      }, {} as any);
    }

    try {
      await this.ensureTwilioCredentialsAdded();
      const content = await postTemplateToTwilio(
        bots.botSettings.twilioSID,
        bots.botSettings.twilioToken,
        payload
      );

      if (template.status === WhatsappTemplateStatus.SUBMITTED) {
        await postTemplateToTwilioForApproval(
          bots.botSettings.twilioSID,
          bots.botSettings.twilioToken,
          content.sid,
          {
            allow_category_change: true,
            category: template.category,
            name: template.name,
          }
        );
      }

      return content.sid;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  deleteTwilioContentTemplate = async (template: WhatsappTemplate) => {
    if (!template.twilioContentSid) {
      return;
    }
    try {
      await this.ensureTwilioCredentialsAdded();
      await deleteTemplateFromTwilio(
        bots.botSettings.twilioSID,
        bots.botSettings.twilioToken,
        template.twilioContentSid
      );
    } catch (error) {
      console.log(error);
      // throw error;
    }
  };

  applyTemplateForApproval = async (template: WhatsappTemplate) => {
    if (!template.twilioContentSid) {
      return;
    }
    try {
      await this.ensureTwilioCredentialsAdded();
      const approval = await postTemplateToTwilioForApproval(
        bots.botSettings.twilioSID,
        bots.botSettings.twilioToken,
        template.twilioContentSid,
        {
          allow_category_change: true,
          category: template.category,
          name: template.name,
        }
      );

      if (approval.status !== "received") {
        throw new Error("Template not approved by Twilio");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getTemplateStatusFromTwilio = async (template: WhatsappTemplate) => {
    if (!template.twilioContentSid) {
      return;
    }
    try {
      await this.ensureTwilioCredentialsAdded();
      const response = await postTemplateStatusTwilio(
        bots.botSettings.twilioSID,
        bots.botSettings.twilioToken,
        template.twilioContentSid
      );

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  ensureTwilioCredentialsAdded = async () => {
    return new Promise((resolve) => {
      if (!bots.botSettings.whatsappEnabled) {
        throw new Error("notEnabled");
      }
      if (!bots.botSettings.twilioSID || !bots.botSettings.twilioToken) {
        throw new Error("unauthroized");
      }
      resolve(true);
    });
  };

  createMetaTemplate = async (template: WhatsappTemplate) => {
    if (!bots.selectedBot) {
      return;
    }

    let extras: any = [];
    let example = undefined;

    if (template?.fieldsMapping?.length) {
      example = {
        body_text: [template?.fieldsMapping.map((f) => f.value)],
      };
    }

    if (template.contentType === ContentType.QUICK_REPLY) {
      extras.push({
        type: "BUTTONS",
        buttons: template.meta?.actions?.map((f: any) => ({
          type: "QUICK_REPLY",
          text: f.title,
        })),
      });
    } else if (template.contentType === ContentType.CTA) {
      extras.push({
        type: "BUTTONS",
        buttons: template.meta?.actions?.map((action: any) => ({
          type:
            action.type === WhatsappTemplateActionButtonType.PHONE_NUMBER
              ? "PHONE_NUMBER"
              : "URL",
          text: action.text,
          [action.type === WhatsappTemplateActionButtonType.PHONE_NUMBER
            ? "phone_number"
            : "url"]: action.value,
        })),
      });
    } else if (template.contentType === ContentType.CATALOG) {
      extras.push({
        type: "BUTTONS",
        buttons: [
          {
            type: "CATALOG",
            text: "View catalog",
          },
        ],
      });
    } else if (template.contentType === ContentType.CAROUSEL) {
      extras.push({
        type: "carousel",
        cards: template.meta?.cards.map((card: any) => ({
          components: [
            {
              type: "header",
              format: "image",
              url: card.media,
            },
            {
              type: "body",
              text: card.body,
            },
            {
              type: "buttons",
              buttons: card.buttons.map((button: any) => {
                if (
                  button.type === WhatsappTemplateActionButtonType.PHONE_NUMBER
                ) {
                  return {
                    type: "PHONE_NUMBER",
                    text: button.text,
                    phone_number: button.value,
                  };
                } else if (
                  button.type === WhatsappTemplateActionButtonType.WEBSITE
                ) {
                  return {
                    type: "URL",
                    text: button.text,
                    url: button.value,
                  };
                } else {
                  return {
                    type: "QUICK_REPLY",
                    text: button.text,
                  };
                }
              }),
            },
          ],
        })),
      });
    }

    try {
      const content = await postTemplateToMetaWhatsapp(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        "message_templates",
        "post",
        {
          name: template.name,
          language: template.messageLanguage,
          category: template.category.toUpperCase(),
          mediaUrl: template.meta?.media,
          components: [
            {
              type: "BODY",
              text: template.messageBody,
              example,
            },
            ...extras,
            // {
            //   type: "BUTTONS",
            //   buttons: [
            //     {
            //       type: "QUICK_REPLY",
            //       text: "Unsubscribe from Promos",
            //     },
            //     {
            //       type: "QUICK_REPLY",
            //       text: "Unsubscribe from All",
            //     },
            //   ],
            // },
          ],
        }
      );

      return content;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getTemplateStatusFromMeta = async (template: WhatsappTemplate) => {
    if (!template.metaId || !bots.selectedBot) {
      return;
    }
    try {
      const response = await postTemplateToMetaWhatsapp(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        `message_templates?name=${template.name}`,
        "get",
        undefined
      );

      return response?.data?.[0];
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  deleteMetaTemplate = async (template: WhatsappTemplate) => {
    if (!template.metaId || !bots.selectedBot) {
      return;
    }
    try {
      await await postTemplateToMetaWhatsapp(
        bots.selectedBot.id,
        bots.selectedBot.key,
        bots.selectedBot.password,
        `message_templates?hsm_id=${template.metaId}&name=${template.name}`,
        "delete",
        undefined
      );
    } catch (error) {
      console.log(error);
      // throw error;
    }
  };

  setCampaigns = (campaigns: Paginate) => {
    this.campaigns = campaigns;
  };

  setSelectedCampaign = (campaign?: Campaign) => {
    this.selectedCampaign = campaign;
  };

  setResults = (results: Message[]) => {
    this.results = results;
  };
}

export default new CampaignsStore();
