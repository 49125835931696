import firebaseApp from "firebase/compat/app";
import { BoardItemCard, SupportedLanguage, User, UserType } from ".";

export enum EdnaChatType {
  SINGLE = "single",
  CHANNEL = "channel", //livecard
  BOT = "bot",
  AI_TEAM = "ai-team",
  CHATHUB_CHAT = "chathub-chat", //private notes
  THREAD = "thread",
  HUB = "hub",
}

export type EdnaChat = {
  id?: string;
  type: EdnaChatType;
  room: string;
  lastMessageUserId?: string;
  lastMessageSeenByUserIds?: string[];
  isChatDisabled?: boolean;
  aITeamCommunicationStartedMessageId?: string;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
  extras?: Record<any, any>;
} & {
  threadChatId?: string; // For threads only
  threadChatType?: EdnaChatType; // For threads only
  threadMessageId?: string;
  hasThreadInteractions?: boolean;
};

export type EdnaChatInfo = {
  id: string | number;
  type: EdnaChatType;
  name: string;
  profileUrl?: string;
  extras?: Record<any, any>;
};

export type UploadedFile = {
  name: string;
  link: string;
  type: string;
};

export type EdnaMessageParser = {
  type: "text" | "file" | "ticket";
  text?: string;
  file?: UploadedFile;
  ticket?: BoardItemCard;
};

// Define a base message structure
export type EdnaMessage = {
  id?: string;
  // chatId: string;
  userId: string; // ID of the user who sent the message (including bots)
  message: EdnaMessageParser; // Modify to hold a string message instead of JSON
  deliveredAt?: firebaseApp.firestore.FieldValue;
  aITeamTaskId?: string;
  seenAt?: firebaseApp.firestore.FieldValue;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
  repliesCount?: number;
};

export const EdnaBotId =
  "a1b9071f5454c3f1782b0c48e0430c3ab1f6b6b7eaa0c227c349f2e59e622f44";

export const ednaBotUser: User = {
  id: EdnaBotId,
  email: "ednabot@edna.ai",
  name: "EDNA Bot",
  avatarUrl: "/assets/edna-bot.jpeg",
  type: UserType.Administrator,
  isDisabled: false,
  preferredLanguage: SupportedLanguage.en,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export type EdnaChatMention = {
  room: string;
  chatId: string;
  messageId: string;
  mentionedUserId: string;
  chat: EdnaChat;
  message?: EdnaMessage;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
};
