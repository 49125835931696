import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Field, Modal, AntSelect } from "../../../components";
import { PhoneField } from "../../../components/phoneField/PhoneField";
import locale from "../../../constants/locale";
import { channelMap, ChatChannel } from "../../../models";
import { useStore } from "../../../stores";
import { isValidPhoneNumber } from "react-phone-number-input";

const AVAILABLE_CHANNELS = [
  ChatChannel.MetaWhatsApp,
  ChatChannel.TwilioWhatsApp,
  ChatChannel.Other,
];

const AddContact = ({
  disabled,
  defaultOpen,
  onClose,
  contact,
}: {
  disabled?: boolean;
  automaticallyAddToBoard?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  contact?: {
    id: string;
    firstName: string;
    channel: ChatChannel;
  };
}) => {
  const { t } = useTranslation();
  const { getBotChannels } = useStore("bots");
  const { newContact, existsContact } = useStore("chats");
  const [open, setOpen] = useState(defaultOpen ?? false);

  return (
    <>
      {!defaultOpen && (
        <button
          disabled={disabled}
          className="button is-rounded is-ghosted"
          onClick={() => setOpen(true)}
        >
          <div className="icon" style={{ position: "absolute" }}>
            <img src="/assets/add-blue.svg" alt="" />
          </div>
        </button>
      )}
      <Formik
        initialValues={{
          id: contact?.id,
          firstName: contact?.firstName,
          lastName: "",
          channel: contact?.channel,
          otherChannel: undefined,
        }}
        validationSchema={yup.object({
          id: yup
            .string()
            .required(t(locale.fieldRequired))
            .test(
              "is-valid-phone-number",
              t(locale.invalidPhoneNumber),
              (value) => !value || isValidPhoneNumber(value)
            ),
          firstName: yup.string().required(t(locale.fieldRequired)),
          lastName: yup.string().required(t(locale.fieldRequired)),
          channel: yup
            .string()
            .required(t(locale.fieldRequired))
            .oneOf(AVAILABLE_CHANNELS),
          otherChannel: yup.string().optional(),
        })}
        onSubmit={async (data, { resetForm, setFieldError }) => {
          if (
            data.id &&
            data.channel &&
            (await existsContact(data.id, data.channel))
          ) {
            setFieldError("id", t(locale.contactAlreadyExists));
            return;
          }

          await newContact(data as any);
          setOpen(false);
          if (onClose) {
            onClose();
          }
          resetForm();
        }}
      >
        {({ isSubmitting, submitForm, resetForm, setFieldValue, values }) => (
          <Modal
            isOpen={open}
            title={t(locale.contact)}
            onClose={() => {
              setOpen(false);
              resetForm();
              if (onClose) {
                onClose();
              }
            }}
            primaryText={() => {
              return (
                <>
                  <img src="/assets/add-borderd.svg" alt="add-borderd" />
                  <span className="ml-2">{t(locale.addContact)}</span>
                </>
              );
            }}
            primaryLoading={isSubmitting}
            onPrimary={isSubmitting ? undefined : submitForm}
          >
            <Form>
              <Field
                name="firstName"
                label={t(locale.firstName)}
                placeholder={"Enter " + t(locale.firstName)}
                className="custom-input"
                disabled={isSubmitting}
              />
              <Field
                name="lastName"
                label={t(locale.lastName)}
                placeholder={"Enter " + t(locale.firstName)}
                className="custom-input"
                disabled={isSubmitting}
              />
              <PhoneField
                name="id"
                label={t(locale.phoneNumber)}
                placeholder={t(locale.phoneNumber)}
                className="custom-input"
                disabled={isSubmitting}
                international
              />
              <AntSelect
                name="channel"
                label={t(locale.channel)}
                disabled={isSubmitting}
                placeholder={`${t(locale.select)} ${t(locale.channel)}`}
                options={AVAILABLE_CHANNELS.filter((f) =>
                  getBotChannels().includes(f)
                )
                  .concat([ChatChannel.Other])
                  .map((m) => ({
                    label: t(channelMap[m]),
                    value: m,
                  }))}
              />
              {values.channel === ChatChannel.Other && (
                <Field
                  name="otherChannel"
                  label={t(locale.nameOfOtherChannel)}
                  placeholder={"Enter " + t(locale.nameOfOtherChannel)}
                  className="custom-input"
                  disabled={isSubmitting}
                />
              )}
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default observer(AddContact);
