import { Button, Form, Input, Progress, Select, Table, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import locale from "../../../constants/locale";
import { Modal } from "../../../components";
import { BoardItemTransaction } from "../../../models";
import { useStore } from "../../../stores";
import { formatNumber } from "../../../utils/numbers";
import DeleteModal from "../../../modals/DeleteModal";

interface TransactionsProps {
  value?: BoardItemTransaction[];
  onChange?: (transactions?: BoardItemTransaction[]) => void;
}

const Transactions = observer(({ value, onChange }: TransactionsProps) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const [transactions, setTransactions] = useState<
    BoardItemTransaction[] | undefined
  >(value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTransaction, setEditingTransaction] =
    useState<BoardItemTransaction | null>(null);
  const [form] = Form.useForm();
  const [toDelete, setToDelete] = useState<BoardItemTransaction>();

  useEffect(() => {
    setTransactions(value);
  }, [value]);

  const handleAddNew = () => {
    setEditingTransaction(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleEdit = (transaction: BoardItemTransaction) => {
    setEditingTransaction(transaction);
    form.setFieldsValue({
      ...transaction,
      date: transaction.date,
    });
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!toDelete) {
      return;
    }
    const newTransactions = transactions?.filter((t) => t.id !== toDelete.id);
    setTransactions(newTransactions);
    onChange?.(newTransactions);
    setToDelete(undefined);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let newTransactions: BoardItemTransaction[];

      if (editingTransaction) {
        // Update existing transaction
        newTransactions =
          transactions?.map((t) =>
            t.id === editingTransaction.id ? { ...values, id: t.id } : t
          ) ?? [];
      } else {
        // Add new transaction
        newTransactions = [
          ...(transactions ?? []),
          {
            ...values,
            id: uuid(),
          },
        ];
      }

      setTransactions(newTransactions);
      onChange?.(newTransactions);
      setIsModalOpen(false);
    });
  };

  const columns = [
    {
      title: t(locale.date),
      dataIndex: "date",
      key: "date",
      sorter: (a: BoardItemTransaction, b: BoardItemTransaction) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      },
    },
    {
      title: t(locale.paymentMethod),
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: t(locale.amount),
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) =>
        formatNumber(amount, {
          currency: selectedWorkspace?.currency,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t(locale.status),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let color = "blue";
        if (status === "completed") color = "green";
        if (status === "in-complete") color = "red";

        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: t(locale.action),
      key: "actions",
      render: (_: any, record: BoardItemTransaction) => (
        <span>
          <button
            type="button"
            onClick={() => handleEdit(record)}
            className="button is-ghost is-no-box-shadow"
          >
            <div className="icon" style={{ position: "absolute" }}>
              <img src="/assets/edit.svg" alt="" />
            </div>
          </button>
          <button
            type="button"
            onClick={() => setToDelete(record)}
            className="button is-ghost is-no-box-shadow"
          >
            <div className="icon" style={{ position: "absolute" }}>
              <img src="/assets/dustbin.svg" alt="" />
            </div>
          </button>
        </span>
      ),
    },
  ];

  // Calculate total and paid amounts
  const totalAmount = (transactions ?? [])?.reduce(
    (sum, transaction) => sum + parseFloat(transaction.amount as any),
    0
  );
  const paidAmount = (transactions ?? [])
    .filter((transaction) => transaction.status === "completed")
    .reduce(
      (sum, transaction) => sum + parseFloat(transaction.amount as any),
      0
    );

  // Calculate percentage for progress
  const progressPercentage =
    totalAmount > 0 ? Math.round((paidAmount / totalAmount) * 100) : 0;

  return (
    <div className="transactions-container">
      <div className="is-flex is-justify-content-space-between is-align-items-center mb-4">
        <div>
          <div className="is-size-5 has-text-weight-bold">
            {t(locale.totalAmount)}:{" "}
            {formatNumber(totalAmount, {
              currency: selectedWorkspace?.currency,
              maximumFractionDigits: 2,
            })}
          </div>
          <div className="is-size-6">
            {t(locale.paid)}:{" "}
            {formatNumber(paidAmount, {
              currency: selectedWorkspace?.currency,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
        <Button type="primary" onClick={handleAddNew}>
          {t(locale.addNewTransaction || "Add New")}
        </Button>
      </div>

      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <Table
          dataSource={transactions}
          columns={columns}
          rowKey="id"
          pagination={false}
          size="small"
          scroll={{
            y: 300,
          }}
        />
        <Progress className="ml-4" type="circle" percent={progressPercentage} />
      </div>

      <Modal
        zIndex={99999999}
        title={
          editingTransaction
            ? t(locale.editTransaction)
            : t(locale.addTransaction)
        }
        isOpen={isModalOpen}
        onPrimary={handleSubmit}
        primaryText={editingTransaction ? t(locale.update) : t(locale.save)}
        onClose={() => setIsModalOpen(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="date"
            label={t(locale.date)}
            rules={[{ required: true, message: t(locale.fieldRequired) }]}
          >
            <Input type="date" />
          </Form.Item>

          <Form.Item
            name="paymentMethod"
            label={t(locale.paymentMethod)}
            rules={[{ required: true, message: t(locale.fieldRequired) }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="amount"
            label={t(locale.amount)}
            rules={[{ required: true, message: t(locale.fieldRequired) }]}
          >
            <Input type="number" min={0} step={0.01} />
          </Form.Item>
          <Form.Item name="description" label={t(locale.description)}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="status"
            label={t(locale.status)}
            rules={[{ required: true, message: t(locale.fieldRequired) }]}
          >
            <Select>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="completed">Completed</Select.Option>
              <Select.Option value="in-complete">In-Complete</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <DeleteModal
        isOpen={!!toDelete}
        onConfirm={handleDelete}
        onCancel={() => setToDelete(undefined)}
        itemName={toDelete?.date || ""}
      />
    </div>
  );
});

export default Transactions;
