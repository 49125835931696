import React from "react";
import ReactLinkify from "react-linkify";

const MessageFormatter = ({ text }: { text: string }) => {
  // Helper function to detect emojis
  const isEmoji = (str: string): boolean => {
    try {
      // Define a more precise emoji regex that excludes digits and ASCII characters
      const emojiRegex =
        /^(?:\p{Emoji_Presentation}|\p{Extended_Pictographic}|\p{Emoji_Modifier_Base}|\p{Emoji_Modifier}|\p{Emoji_Component})$/u;

      // Check if str is a markdown formatting character or ASCII symbol
      // eslint-disable-next-line no-useless-escape
      const markdownRegex = /^[\*\_\~\`\#\>\-\+\.\[\]\(\)\{\}\!\|\\0-9]$/;

      // If it's a markdown character, return false
      if (markdownRegex.test(str)) {
        return false;
      }

      // Otherwise, test if it's an emoji
      return emojiRegex.test(str);
    } catch (error) {
      console.error("Error in isEmoji function:", error);
      return false;
    }
  };

  // Helper function to check if text contains only emojis
  const containsOnlyEmojis = (str: string) => {
    try {
      if (!str || !str.trim()) return false;

      // Remove any whitespace from the string
      const trimmedStr = str.trim();

      // Check if each character is an emoji
      for (const char of trimmedStr) {
        if (!isEmoji(char) && char !== " " && char !== "\n") {
          return false;
        }
      }

      return true;
    } catch (error) {
      console.error("Error in containsOnlyEmojis function:", error);
      return false;
    }
  };

  const parseMessageEnhanced = (messageText: string) => {
    try {
      if (!messageText) return [];

      // Check if the entire message contains only emojis
      const entireMessageOnlyEmojis = containsOnlyEmojis(messageText);

      // Split by newlines to process each line
      const lines = messageText.split("\n");
      const formattedParts = [] as React.ReactNode[];

      // Process quote blocks and list blocks
      let inQuoteBlock = false;
      let quoteContent: React.ReactNode[] = [];

      let inBulletList = false;
      let bulletListContent: React.ReactNode[] = [];

      let inNumberedList = false;
      let numberedListContent: React.ReactNode[] = [];

      lines.forEach((line, lineIndex) => {
        // Check for bullet points (lines starting with -)
        const isBulletPoint = line.trim().startsWith("-");

        // Check for numbered list items (lines starting with 1., 2., etc.)
        const numberedMatch = line.trim().match(/^(\d+)\.\s(.+)/);
        const isNumberedItem = !!numberedMatch;

        // Check for quote lines (starting with >)
        const isQuoteLine = line.trim().startsWith(">");

        if (isBulletPoint) {
          // End other block types if they're open
          if (inQuoteBlock) {
            formattedParts.push(
              <div
                key={`quote-block-${lineIndex}`}
                className="quote-block"
                style={{
                  borderLeft: "3px solid #ccc",
                  paddingLeft: "10px",
                  margin: "8px 0",
                }}
              >
                {quoteContent}
              </div>
            );
            inQuoteBlock = false;
            quoteContent = [];
          }

          if (inNumberedList) {
            formattedParts.push(
              <ol
                key={`numbered-list-${lineIndex}`}
                style={{
                  margin: "4px 0 4px 20px",
                }}
              >
                {numberedListContent}
              </ol>
            );
            inNumberedList = false;
            numberedListContent = [];
          }

          // Extract content after the bullet point
          const bulletContent = line.trim().substring(1).trim();
          // Format the bullet content with inline styles
          const formattedBulletContent = formatInlineStyles(
            bulletContent,
            lineIndex,
            entireMessageOnlyEmojis
          );

          // Start or continue a bullet list
          if (!inBulletList) {
            inBulletList = true;
          }

          bulletListContent.push(
            <li key={`bullet-item-${lineIndex}`}>
              <ReactLinkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    href={decoratedHref}
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {formattedBulletContent}
              </ReactLinkify>
            </li>
          );
        } else if (isNumberedItem) {
          // End other block types if they're open
          if (inQuoteBlock) {
            formattedParts.push(
              <div
                key={`quote-block-${lineIndex}`}
                className="quote-block"
                style={{
                  borderLeft: "3px solid #ccc",
                  paddingLeft: "10px",
                  margin: "8px 0",
                }}
              >
                {quoteContent}
              </div>
            );
            inQuoteBlock = false;
            quoteContent = [];
          }

          if (inBulletList) {
            formattedParts.push(
              <ul
                key={`bullet-list-${lineIndex}`}
                style={{
                  margin: "4px 0 4px 20px",
                  listStyleType: "disc",
                }}
              >
                {bulletListContent}
              </ul>
            );
            inBulletList = false;
            bulletListContent = [];
          }

          // Extract content from the numbered item
          const numberedContent = numberedMatch![2];
          // Format the numbered content with inline styles
          const formattedNumberedContent = formatInlineStyles(
            numberedContent,
            lineIndex,
            entireMessageOnlyEmojis
          );

          // Start or continue a numbered list
          if (!inNumberedList) {
            inNumberedList = true;
          }

          numberedListContent.push(
            <li
              key={`numbered-item-${lineIndex}`}
              value={parseInt(numberedMatch![1])}
            >
              <ReactLinkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    href={decoratedHref}
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {formattedNumberedContent}
              </ReactLinkify>
            </li>
          );
        } else {
          // Handle end of any open list blocks
          if (inBulletList) {
            formattedParts.push(
              <ul
                key={`bullet-list-${lineIndex}`}
                style={{
                  margin: "4px 0 4px 20px",
                  listStyleType: "disc",
                }}
              >
                {bulletListContent}
              </ul>
            );
            inBulletList = false;
            bulletListContent = [];
          }

          if (inNumberedList) {
            formattedParts.push(
              <ol
                key={`numbered-list-${lineIndex}`}
                style={{
                  margin: "4px 0 4px 20px",
                }}
              >
                {numberedListContent}
              </ol>
            );
            inNumberedList = false;
            numberedListContent = [];
          }

          if (isQuoteLine) {
            // Extract content after >
            const quoteLineContent = line.trim().substring(1).trim();

            // Format the quote line content with inline styles
            const formattedQuoteLine = formatInlineStyles(
              quoteLineContent,
              lineIndex,
              entireMessageOnlyEmojis
            );

            // Start or continue a quote block
            if (!inQuoteBlock) {
              inQuoteBlock = true;
            }

            quoteContent.push(
              <div key={`quote-line-${lineIndex}`} className="quote-line">
                <ReactLinkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      href={decoratedHref}
                      key={key}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  {formattedQuoteLine}
                </ReactLinkify>
              </div>
            );
          } else {
            // If we're ending a quote block
            if (inQuoteBlock) {
              formattedParts.push(
                <div
                  key={`quote-block-${lineIndex}`}
                  className="quote-block"
                  style={{
                    borderLeft: "3px solid #ccc",
                    paddingLeft: "10px",
                    color: "#555",
                  }}
                >
                  {quoteContent}
                </div>
              );
              inQuoteBlock = false;
              quoteContent = [];
            }

            // Process regular line with inline formatting
            const formattedLine = formatInlineStyles(
              line,
              lineIndex,
              entireMessageOnlyEmojis
            );

            // If entire message contains only emojis, use a larger size
            if (entireMessageOnlyEmojis && line.trim()) {
              formattedParts.push(
                <div
                  key={`line-${lineIndex}`}
                  className="message-line emoji-only"
                  style={{ fontSize: "40px", lineHeight: "1.2" }}
                >
                  {line}
                </div>
              );
            } else {
              formattedParts.push(
                <div
                  key={`line-${lineIndex}`}
                  className="message-line"
                  style={!line.trim() ? { minHeight: "1em" } : undefined}
                >
                  <ReactLinkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        href={decoratedHref}
                        key={key}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    {formattedLine}
                  </ReactLinkify>
                </div>
              );
            }
          }
        }
      });

      // Handle any unclosed blocks at the end
      // If we ended with a bullet list still open
      if (inBulletList) {
        formattedParts.push(
          <ul
            key="bullet-list-final"
            style={{
              margin: "4px 0 4px 20px",
              listStyleType: "disc",
            }}
          >
            {bulletListContent}
          </ul>
        );
      }

      // If we ended with a numbered list still open
      if (inNumberedList) {
        formattedParts.push(
          <ol
            key="numbered-list-final"
            style={{
              margin: "4px 0 4px 20px",
            }}
          >
            {numberedListContent}
          </ol>
        );
      }

      // If we ended with a quote block still open
      if (inQuoteBlock) {
        formattedParts.push(
          <div
            key="quote-block-final"
            className="quote-block"
            style={{
              borderLeft: "3px solid #ccc",
              paddingLeft: "10px",
              margin: "8px 0",
            }}
          >
            {quoteContent}
          </div>
        );
      }

      return formattedParts;
    } catch (error) {
      console.error("Error in parseMessageEnhanced function:", error);
      // Return original text wrapped in ReactLinkify if any error occurs during parsing
      return (
        <ReactLinkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </a>
          )}
        >
          <div>{text}</div>
        </ReactLinkify>
      );
    }
  };

  // Enhanced formatInlineStyles with emoji detection
  const formatInlineStyles = (
    line: string,
    lineIndex: number,
    entireMessageOnlyEmojis: boolean = false
  ) => {
    try {
      // If entire message is only emojis, return the text with larger font size
      if (entireMessageOnlyEmojis && line.trim()) {
        return <span style={{ fontSize: "40px" }}>{line}</span>;
      }

      // Define patterns and their corresponding formatting for inline styles only
      const patterns = [
        // Italic for underlined text: _text_
        {
          regex: /_(.*?)_/g,
          render: (_: any, content: string) => (
            <span className="is-italic">{content}</span>
          ),
        },
        // Bold for bold text: *text*
        {
          regex: /\*(.*?)\*/g,
          render: (_: any, content: string) => (
            <span className="has-text-weight-bold">{content}</span>
          ),
        },
        // Strike for decorative text: ~text~
        {
          regex: /~(.*?)~/g,
          render: (_: any, content: string) => (
            <span
              style={{
                textDecoration: "line-through",
              }}
            >
              {content}
            </span>
          ),
        },
      ];

      // Create segments by splitting with all regex patterns
      let segments = [{ text: line, formatted: false }] as any[];

      // Apply each pattern to further split segments
      patterns.forEach((pattern) => {
        const newSegments = [] as any[];
        segments.forEach((segment) => {
          if (segment.formatted) {
            // Already formatted segments should be preserved
            newSegments.push(segment);
            return;
          }
          const parts = [];
          let lastIndex = 0;
          let match;
          // Reset regex lastIndex
          pattern.regex.lastIndex = 0;
          while ((match = pattern.regex.exec(segment.text)) !== null) {
            // Add text before match
            if (match.index > lastIndex) {
              const textBefore = segment.text.substring(lastIndex, match.index);
              parts.push({
                text: textBefore,
                formatted: false,
              });
            }
            // Add formatted match
            parts.push({
              text: match[0],
              originalText: match[1],
              pattern: pattern,
              formatted: true,
            });
            lastIndex = match.index + match[0].length;
          }
          // Add remaining text
          if (lastIndex < segment.text.length) {
            const textAfter = segment.text.substring(lastIndex);
            parts.push({
              text: textAfter,
              formatted: false,
            });
          }
          // If no matches were found, keep original segment
          if (parts.length === 0) {
            newSegments.push(segment);
          } else {
            newSegments.push(...parts);
          }
        });
        segments = newSegments;
      });

      // Render segments to React elements
      const reactElements = segments.map((segment, index) => {
        if (segment.formatted) {
          return React.cloneElement(
            segment.pattern.render(segment.text, segment.originalText),
            { key: `${lineIndex}-fmt-${index}` }
          );
        }

        // Check if this segment contains any emojis
        let hasEmojis = false;

        for (const char of segment.text) {
          if (isEmoji(char)) {
            hasEmojis = true;
            break;
          }
        }

        // Handle emoji sizing (if not in an emoji-only message)
        if (hasEmojis && !entireMessageOnlyEmojis) {
          // Format emojis to be 20px in mixed content
          const formattedText = Array.from(segment.text).map(
            (char: any, charIndex) => {
              if (isEmoji(char)) {
                return (
                  <span
                    key={`emoji-${lineIndex}-${index}-${charIndex}`}
                    style={{
                      fontSize: "20px",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    {char}
                  </span>
                );
              }
              return char;
            }
          );

          return (
            <span key={`${lineIndex}-plain-${index}`}>{formattedText}</span>
          );
        }

        return <span key={`${lineIndex}-plain-${index}`}>{segment.text}</span>;
      });

      return reactElements.length > 0 ? reactElements : line;
    } catch (error) {
      console.error("Error in formatInlineStyles function:", error);
      // Return original line if any error occurs during formatting
      return line;
    }
  };

  // Main wrapper with global try-catch
  try {
    // Process the message with formatting and URL detection
    return parseMessageEnhanced(text) as any;
  } catch (error) {
    console.error("Error in MessageFormatter component:", error);
    // Ultimate fallback to original text with ReactLinkify
    return (
      <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            href={decoratedHref}
            key={key}
            target="_blank"
            rel="noopener noreferrer"
          >
            {decoratedText}
          </a>
        )}
      >
        <div>{text}</div>
      </ReactLinkify>
    );
  }
};

export default MessageFormatter;
