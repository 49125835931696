// import { useRef } from "react";
// @ts-ignore
import clsx from "clsx";
import { FieldConfig, useField } from "formik";
import { TFunctionResult } from "i18next";
import { HTMLProps, useRef } from "react";

type Props = {
  label?: React.ReactChild | TFunctionResult;
  icon?: string | (() => React.ReactChild);
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  useTextArea?: boolean;
  tooltip?: string;
  className?: string;
  isHorizontal?: boolean;
  inputProps?: HTMLProps<HTMLInputElement> | HTMLProps<HTMLTextAreaElement>;
  labelClass?: string,
  iconPosition?: string
  fieldClassName?: string
} & FieldConfig<string>;

export function TagsInputV2({ children, ...props }: Props) {
  const [field, meta, { setValue }] = useField(props);
  // @ts-ignore
  const { value } = field;

  const ref = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const label = typeof props.label === "function" ? props.label() : props.label;


  const { value: v1, onChange: oC, ...inputProps } = {
    ...props.inputProps,
    ...field,
    className: clsx("input", props.className, {
      "is-danger": meta.touched && meta.error,
    }),
    type: props.type,
    placeholder: props.placeholder,
    disabled: props.disabled,
    readOnly: props.readOnly,
    tooltip: props.tooltip,
  };

  const labelHtml = !!label && (
    <label className="label">
      {label}
      {!!props.tooltip && <p className="label is-small">({props.tooltip})</p>}
    </label>
  );

  const removeTags = (indexToRemove: Number) => {
    // @ts-ignore
    const newTags = value.filter((_: any, index: Number) => index !== indexToRemove);
    setValue(newTags);
  };

  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value.trim() !== "") {
      const newTags = [...value, event.currentTarget.value.trim()];
      //@ts-ignore
      setValue(newTags);
      event.currentTarget.value = "";
    }
  };

  const input = (
    <>
      <div
        className={clsx("control", {
          "has-icons-left": !!props.icon,
        })}
      >

        <div className="tags-input-v2">
          <ul id="tags-v2">
            {Array.from(value).map((tag: string, index: number) => (
              <li key={index} className="tag-v2">
                <span className="tag-title">{tag}</span>
                <div
                  className="delete is-small"
                  data-tag="delete"
                  onClick={() => removeTags(index)}
                ></div>
              </li>
            ))}
          </ul>
          <input
            ref={ref}
            {...(inputProps as any)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                addTags(event);
              }
            }}
          />
          <button onClick={() => {
            const enterEvent = new KeyboardEvent("keydown", {
              key: "Enter",
              code: "Enter",
              keyCode: 13,
              which: 13,
              bubbles: true,
              cancelable: true,
            });
            ref.current?.dispatchEvent(enterEvent);
          }} className="button is-ghost is-no-box-shadow p-0">
            <span className="icon">
              <i className={clsx("fas fa-add")}></i>
            </span>
          </button>
        </div>

        {!!props.icon && (
          <span
            className="icon is-small is-left"
            style={{
              pointerEvents: typeof props.icon === "string" ? "none" : "all",
            }}
          >
            {typeof props.icon === "string" ? (
              <i className={clsx("fas", props.icon)}></i>
            ) : (
              props.icon()
            )}
          </span>
        )}
      </div>
      {meta.touched && !!meta.error && (
        <p className="help is-danger">{meta.error}</p>
      )}
    </>
  );

  return (
    <div className={clsx("field", { "is-horizontal": props.isHorizontal })}>
      {!props.isHorizontal ? (
        <>
          {labelHtml}
          {input}
        </>
      ) : (
        <>
          <div className="field-label is-normal">{labelHtml}</div>
          <div className="field-body">
            <div className="field">{input}</div>
          </div>
        </>
      )}
    </div>
  );
}
