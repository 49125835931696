import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Hub, userStatusColorMap } from "../../../../models";
import { FallbackImage } from "../../../fallbackImage/FallbackImage";
import { useStore } from "../../../../stores";
import locale from "../../../../constants/locale";
import styles from "../ChatInfo.module.scss";

const HubInfo = observer(({ hub }: { hub: Hub }) => {
  const { t } = useTranslation();
  const { currentHubUsers } = useStore("hubs");

  return (
    <>
      <div className="has-border-bottom pb-4 mt-4 is-relative">
        <h2 className="title is-6 mb-1">{t(locale.members)}</h2>
        <div>
          {!hub.members?.length && (
            <div className="has-text-grey-light">
              No members in this hub yet
            </div>
          )}
          {currentHubUsers?.map((user) => {
            const member = hub.members?.find((m) => m.id === user.id);
            return (
              <div key={user.id} className="is-flex is-relative py-2">
                <figure className="is-flex-shrink-0">
                  <p className="image is-40x40 is-square">
                    <FallbackImage
                      className="is-rounded"
                      src={user.avatarUrl}
                      fallback="/assets/avatar.svg"
                      alt={user.name}
                      title={user.name}
                    />
                    {user.status && (
                      <div
                        className={clsx("ml-1 ", styles.userStatus)}
                        style={{
                          background: userStatusColorMap[user.status as never],
                        }}
                      />
                    )}
                  </p>
                </figure>
                <div className="is-flex is-flex-grow-1 is-align-items-stretch is-justify-content-center is-flex-direction-column ml-3">
                  <div className="is-flex is-justify-content-space-between">
                    <span
                      title={user.name}
                      className={clsx(
                        "pr-3 has-ellipsis-text has-text-weight-medium",
                        styles.userName
                      )}
                    >
                      {user.name}
                    </span>
                    {member && (
                      <>
                        {!member?.invitationAccepted &&
                          !member?.invitationRejected && <div>Invited</div>}
                        {member?.invitationRejected && <div>Rejected</div>}
                      </>
                    )}
                    {user?.id === hub.creatorId && <div>Owner</div>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});

export default HubInfo;
