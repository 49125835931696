import React, { useCallback, useEffect, useState } from "react";
import { FullScreenLoader } from "../../../components";
import { useStore } from "../../../stores";
import { observer } from "mobx-react-lite";

const WithPreloadedBoard = ({
  livecardId,
  children,
}: {
  children: React.ReactNode;
  livecardId?: number;
}) => {
  const { setBoardMode, findBoardByLivecardId } = useStore("boards");
  const [loadingTicket, setLoadingTicket] = useState(true);

  const loadTicketConfig = useCallback(async () => {
    setLoadingTicket(true);
    setBoardMode("ticket");
    try {
      await findBoardByLivecardId(String(livecardId));
    } catch (error) {
    } finally {
      setLoadingTicket(false);
    }
  }, [setBoardMode, findBoardByLivecardId, livecardId]);

  useEffect(() => {
    loadTicketConfig();
  }, [loadTicketConfig]);

  if (loadingTicket) {
    return <FullScreenLoader isOpen />;
  }

  return <>{children}</>;
};

export default observer(WithPreloadedBoard);
