import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import {
  SystemFeature,
  Workspace,
  WorkspaceFeature,
  WorkspaceFunction,
  WorkspaceSettings,
  WorkspaceWithoutId,
  functions,
} from "../models/Workspace";
import auth from "./auth";
import { uploadImage } from "./utils";
import aITeams from "./aITeams";
import users from "./users";
import bots from "./bots";
import routes from "../constants/routes";
import { getLiveChatCards, postWorkspaceEvent } from "../services/chathub";
import { LiveChatCard } from "../models";
import { filterLiveCards } from "../utils/filterLiveCards";
import notifications from "./notifications";
import { logError } from "../services/logging";
import { HelpCenter } from "../models/HelpCenter";

class WorkspacesStore {
  private db: firebase.firestore.Firestore;
  selectedWorkspace?: Workspace;
  selectedFunction?: WorkspaceFunction;
  workspaceLoaded = false;
  functionLoaded = false;
  userWorkspaces: Workspace[] = [];
  liveChatCards: LiveChatCard[] = [];
  activeLiveChatCards: LiveChatCard[] = [];
  userDeactivatedForWorkspace = false;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  selectWorkspace = async (workspaceId: string, refreshOnly = false) => {
    if (
      !auth.user ||
      !auth.user.workspaceIds?.includes(workspaceId) ||
      this.checkUserDeactivatedForWorkspace(workspaceId)
    ) {
      throw Error("notFound");
    }

    this.workspaceLoaded = false;

    const workspaceRef = this.db.collection("workspaces").doc(workspaceId);
    const workspaceSnapshot = await workspaceRef.get();
    if (!workspaceSnapshot.exists) {
      return;
    }
    const workspaceData = workspaceSnapshot.data() as Workspace;
    workspaceData.id = workspaceSnapshot.id;
    this.updateTheme(workspaceData.theme);

    const functionsRef = workspaceRef.collection("functions");
    const functionsSnapshot = await functionsRef.get();

    const functionsData = [];
    for (const functionDoc of functionsSnapshot.docs) {
      const functionData = functionDoc.data() as WorkspaceFunction;
      if (
        auth.userHasAdminAccess ||
        auth?.user?.permissions?.perWorkspace?.[workspaceId]?.perFunction?.[
          functionData.slug
        ]?.isFunctionActive
      ) {
        functionsData.push(functionData);
      }
    }
    workspaceData.functions = functionsData;

    this.setWorkspace(workspaceData);
    bots.clearSelectedBot();
    auth.setMyQueueCountInTitle();

    if (!refreshOnly) {
      await users.loadAllUsers(workspaceId);
      await users.loadUsers(workspaceId);
      await this.loadUserWorkspaces();
      await aITeams.loadAITeams();
      await this.loadLiveChatCards();
      notifications.subscribedNotifications();
      const selectedFunction = functionsData?.find((f) => f.slug === "op");
      //TODO need better solutions once we have many functions
      if (selectedFunction && selectedFunction?.meta?.bot?.id) {
        await bots.selectBot({
          id: selectedFunction.meta.bot.id,
          key: selectedFunction.meta.bot.key,
          password: selectedFunction.meta.bot.password,
        });
      }
    }

    this.selectedFunction = undefined;
    this.workspaceLoaded = true;
  };

  selectFunction = async (functionSlug: string) => {
    this.functionLoaded = false;
    const workspaceFunction = this.selectedWorkspace?.functions
      ?.filter((f) => f.active)
      ?.find((f) => f.slug === functionSlug);

    if (!workspaceFunction) {
      return;
    }

    this.selectedFunction = workspaceFunction;

    if (workspaceFunction?.meta) {
      await bots.selectBot({
        id: workspaceFunction.meta.bot.id,
        key: workspaceFunction.meta.bot.key,
        password: workspaceFunction.meta.bot.password,
      });
    }
    this.functionLoaded = true;
  };

  loadUserWorkspaces = async () => {
    if (!auth.user || !auth.user?.workspaceIds?.length) {
      return;
    }

    const workspaces = [];

    for (const workspaceId of auth.user.workspaceIds) {
      const snapshot = await this.db
        .collection("workspaces")
        .doc(workspaceId)
        .get();

      if (snapshot.exists) {
        const workspace = snapshot.data() as Workspace;
        workspace.id = snapshot.id;
        workspaces.push(workspace);
      }
    }

    this.userWorkspaces = workspaces;
  };

  createWorkspace = async (workspace: WorkspaceWithoutId) => {
    if (!auth.user) {
      return;
    }

    const ref = await this.db.collection("workspaces").add(workspace);

    await this.db
      .collection("users")
      .doc(auth.user?.id)
      .update({
        workspaceIds: [...(auth.user?.workspaceIds || []), ref.id],
      });

    auth.setUser({
      ...auth.user,
      workspaceIds: [...(auth.user?.workspaceIds || []), ref.id],
    });

    if (auth.user?.affiliateId) {
      await this.addReferralInAffiliate(
        auth.user.affiliateId,
        workspace.companyName,
        String(auth.user.name),
        auth.user.email
      );
    }

    await postWorkspaceEvent(ref.id, "workspace_created");

    return ref.id as string;
  };

  addFunction = async (functionData: WorkspaceFunction) => {
    if (!auth.user || !this.selectedWorkspace?.id) {
      return;
    }

    if (functionData.slug === "op") {
      const cb = firebase.functions().httpsCallable("createBot");
      const { data } = await cb({
        name: String(auth.user.name),
        email: auth.user.email,
        workspace_name: this.selectedWorkspace.companyName,
        workspace_id: this.selectedWorkspace.id,
      });

      functionData.meta = {
        bot: data.data,
      };
    }

    //add all features by default
    functionData.features = functions
      .find((f) => f.slug === functionData.slug)
      ?.features?.map((f) => ({
        key: f.key,
        name: f.name,
        modules: f.modules,
      }));
    functionData.active = true;

    await this.db
      .collection("workspaces")
      .doc(this.selectedWorkspace.id)
      .collection("functions")
      .doc(functionData.slug)
      .set(functionData);

    this.setWorkspace({
      ...this.selectedWorkspace,
      functions: [...(this.selectedWorkspace?.functions || []), functionData],
    });
  };

  updateFunction = async (functionData: WorkspaceFunction) => {
    if (!auth.user || !this.selectedWorkspace?.id) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(this.selectedWorkspace.id)
      .collection("functions")
      .doc(functionData.slug)
      .set(functionData, { merge: true });

    this.setWorkspace({
      ...this.selectedWorkspace,
      functions: this.selectedWorkspace?.functions?.map((f) =>
        f.slug === functionData.slug ? functionData : f
      ),
    });
  };

  addFeature = async (
    functionSlug: string,
    systemFeature: SystemFeature,
    condition: "add" | "remove"
  ) => {
    if (
      !auth.user ||
      !this.selectedWorkspace?.id ||
      !this.selectedWorkspace?.functions
    ) {
      return;
    }

    const functions = [...this.selectedWorkspace?.functions];

    if (condition === "add") {
      let workspaceFeature: WorkspaceFeature = {
        key: systemFeature.key,
        name: systemFeature.name,
        modules: systemFeature.modules,
      };

      const features = functions.find((f) => f.slug === functionSlug)?.features;

      features?.push(workspaceFeature);

      await this.db
        .collection("workspaces")
        .doc(this.selectedWorkspace.id)
        .collection("functions")
        .doc(functionSlug)
        .set(
          {
            features,
          },
          { merge: true }
        );
    } else {
      const features = functions.find((f) => f.slug === functionSlug)?.features;

      const systemFeatureIndex =
        features?.findIndex((f) => f.key === systemFeature.key) ?? -1;

      if (systemFeatureIndex > -1) {
        features?.splice(systemFeatureIndex, 1);

        await this.db
          .collection("workspaces")
          .doc(this.selectedWorkspace.id)
          .collection("functions")
          .doc(functionSlug)
          .set(
            {
              features,
            },
            { merge: true }
          );
      }
    }

    this.setWorkspace({
      ...this.selectedWorkspace,
      functions,
    });
  };

  updateWorkspace = async (workspace: any) => {
    if (!auth.user || !this.selectedWorkspace?.id) {
      return;
    }

    delete workspace.functions;

    if (workspace?.logo && workspace?.logo !== this.selectedWorkspace?.logo) {
      const img = await uploadImage(
        workspace.logo as any,
        this.selectedWorkspace.id
      );
      if (img) workspace.logo = img;
    }

    await this.db
      .collection("workspaces")
      .doc(this.selectedWorkspace.id)
      .set(workspace, {
        merge: true,
      });

    this.setWorkspace({
      ...this.selectedWorkspace,
      ...workspace,
    });

    this.updateTheme(workspace.theme);
  };

  updateWorkspaceSettings = async (settings: WorkspaceSettings) => {
    if (!auth.user || !this.selectedWorkspace?.id) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(this.selectedWorkspace.id)
      .set(
        {
          settings: {
            ...this.selectedWorkspace?.settings,
            ...settings,
          },
        },
        { merge: true }
      );

    this.setWorkspace({
      ...this.selectedWorkspace,
      settings: {
        ...this.selectedWorkspace?.settings,
        ...settings,
      },
    });
  };

  updateHelpCenterSettings = async (settings: HelpCenter) => {
    if (!auth.user || !this.selectedWorkspace?.id) {
      return;
    }

    await this.db.collection("workspaces").doc(this.selectedWorkspace.id).set(
      {
        helpCenterSettings: settings,
      },
      { merge: true }
    );

    this.setWorkspace({
      ...this.selectedWorkspace,
      helpCenterSettings: settings,
    });
  };

  findActiveModuleOfFunction = (functionSlug: string) => {
    const defaultRoute = routes.chats.replace("/:chatId?", "");

    if (!this.selectedWorkspace) {
      return defaultRoute;
    }

    const functionData = this.selectedWorkspace?.functions?.find(
      (f) => f.slug === functionSlug
    );

    let allModules = functionData?.features?.flatMap((f) => f.modules) || [];

    if (!allModules?.length) {
      return defaultRoute;
    }

    const map = {
      CHATS: defaultRoute,
      QUICK_REPLIES: routes.cannedResponses,
      CONTACTS: routes.contacts,
      LIVE_STREAM: routes.liveStream,
      CAMPAIGNS: routes.campaigns,
      BOARD: routes.board,
      COMMERCE: routes.commerceProfile,
      CATALOG: routes.catalog,
      ANALYTICS: routes.analytics,
    } as Record<string, string>;

    const restrictedModules =
      auth.user?.permissions?.perWorkspace?.[this.selectedWorkspace.id]
        ?.perFunction?.[functionSlug]?.restrictedModules;

    allModules = allModules.filter(
      (m) => !restrictedModules?.includes(m as never)
    );

    return map[allModules[0] as never] || defaultRoute;
  };

  loadLiveChatCards = async () => {
    if (!auth.user || !this.selectedWorkspace) {
      return;
    }

    const selectedFunction = this.selectedWorkspace?.functions?.find(
      (f) => f.slug === "op"
    );
    if (selectedFunction) {
      const cards = await getLiveChatCards(
        selectedFunction?.meta?.bot?.id,
        selectedFunction?.meta?.bot?.key,
        selectedFunction?.meta?.bot?.password
      );
      if (cards) {
        this.setLiveChatCards(cards);
        this.setActiveLiveChatCards(
          filterLiveCards(
            cards,
            auth.user,
            this.selectedWorkspace,
            selectedFunction
          )
        );
      }
    }
  };

  findWorkspace = async (workspaceId: string) => {
    const snapshot = await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .get();

    if (!snapshot.exists) {
      throw new Error("notFound");
    }
    const workspace = snapshot.data() as Workspace;
    workspace.id = snapshot.id;
    return workspace;
  };

  reloadCurrentWorkspace = async () => {
    if (!this.selectedWorkspace) {
      return;
    }

    const workspaceSnapshot = await this.db
      .collection("workspaces")
      .doc(this.selectedWorkspace.id)
      .get();

    if (!workspaceSnapshot.exists) {
      return;
    }
    const workspaceData = workspaceSnapshot.data() as Workspace;
    workspaceData.id = workspaceSnapshot.id;

    this.setWorkspace({
      ...this.selectedWorkspace,
      ...workspaceData,
    });
  };

  private addReferralInAffiliate = async (
    affiliate: string,
    workspaceName: string,
    name: string,
    email: string
  ) => {
    try {
      const cb = firebase.functions().httpsCallable("addReferralInAffiliate");

      const { data } = await cb({
        affiliate,
        workspaceName,
        name,
        email,
      });

      if (!data?.success) {
        throw new Error(data.message || "Something went wrong");
      }
    } catch (e) {
      logError(e);
    }
  };

  private setActiveLiveChatCards = (cards: LiveChatCard[]) => {
    this.activeLiveChatCards = cards;
  };

  private setLiveChatCards = (cards: LiveChatCard[]) => {
    this.liveChatCards = cards;
  };

  private setWorkspace = (workspace: Workspace) => {
    this.selectedWorkspace = workspace;
  };

  private checkUserDeactivatedForWorkspace = (workspaceId: string) => {
    return (
      auth.user?.permissions?.perWorkspace?.[workspaceId]?.userDeactivated ||
      false
    );
  };

  clearSelectedWorkspace = () => {
    this.selectedWorkspace = undefined;
  };

  updateTheme = (theme: Workspace["theme"]) => {
    if (
      !theme ||
      (theme?.primary?.toLowerCase() === "#2a79ef" &&
        theme?.text?.toLowerCase() === "#ffffff")
    ) {
      document.getElementById("custom-theme")?.remove();
      return;
    }

    // Create a new style element
    const styleElement = document.createElement("style");
    styleElement.setAttribute("id", "custom-theme");
    styleElement.setAttribute("type", "text/css");

    // Make sure this style is added after existing stylesheets
    styleElement.setAttribute("priority", "high");

    const primary = theme?.primary;
    const text = theme?.text;

    // Add the CSS content
    styleElement.textContent = `
      .has-background-primary, .has-background-primary-with-text, .bubble-right {
        background-color: ${primary} !important;
      }
      .button.is-primary{
        background-color: ${primary} !important;
      }
      .button.is-primary.is-outlined {
        background-color: transparent !important;
        border-color: ${primary} !important;
        color: ${primary} !important;
      }
      .button.is-primary.is-outlined:hover {
        background-color: ${primary} !important; 
        border-color: ${text} !important;
        color: ${text} !important;
      }
      .custom-tabs li.is-active a {
        background: ${primary} !important;
        color: ${text} !important;
      }
      .custom-tabs li a {
        color: ${primary} !important;
      }
      .switch[type=checkbox]:checked + label::before, .switch[type=checkbox]:checked + label:before {
        background: ${primary} !important;
      }
      input[type=checkbox]::before{
       background-color: ${primary} !important; 
      }
      input[type=checkbox]:checked {
        border-color: ${primary} !important;
      }
      .has-text-primary{
        color: ${primary} !important;
      }
      .has-background-danger {
        background-color: #f55755 !important;
      }
      .has-background-warning-dark {
        background-color: #e6a500 !important;
      }
      .has-text-white-bis {
        color: #fafafa !important;
      }
  `;

    // Append the style element to the end of head
    // This ensures it has higher precedence than existing stylesheets
    document.head.appendChild(styleElement);
  };
}

export default new WorkspacesStore();
