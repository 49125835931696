import { DateTime } from "luxon";
import {
  Bot,
  Chat,
  ChatTab,
  Message,
  MetricData,
  RequestStatus,
  Schedule,
  User,
  UserAssignedChat,
  LiveStream,
  LiveChatCard,
  LiveChatFilters,
  Campaign,
  ChatChannel,
  CampaignDataType,
  SourceInfo,
  TeamPerformanceInfo,
  Booking,
} from "../models";
import { EmbeddingData } from "../models/HelpCenter";

import { logError } from "./logging";
import { ChatActivity } from "../pages/chats/components/Chat360Modal/components/Activities";
import auth from "../stores/auth";

const botAdapter = ({
  id,
  image,
  language,
  name,
  name_page,
  page_id,
  webhook_key,
  webhook_password,
  agents_schedule,
  livecard_schedule,
  agents_schedule_message,
  agents_schedule_dialog,
  fb_country,
  feature_flags,
  has_video_call_enabled,
  closing_dialog,
  closing_dialog_type,
  closing_dialog_schedule,
  inactivity_dialog,
  inactivity_dialog_schedule,
  max_chat_assigned,
  chat_assignment_type,
  auto_assign_chat_user_types,
  edna_bot_enabled,
  queue_position_enabled,
  queue_position_dialog,
  auto_assign_request_on_next_day_enabled,
  chat_transferred_timeout,
  auto_assign_deal_user_types,
  workspace_id,
  auto_assign_chat_round_robin_chat,
  round_robin_chat_timeout,
  disable_acm_for_chat_transfer,
  disable_ccd_for_chat_transfer,
  whatsapp_enabled,
  whatsapp_provider,
  voice_call_provider,
  voice_call_enabled,
}: any): Bot => ({
  id,
  workspaceId: workspace_id,
  image,
  language,
  name,
  facebook: {
    pageName: name_page,
    pageId: page_id,
  },
  key: webhook_key,
  password: webhook_password,
  settings: {
    timezone: fb_country,
    agentsOutOfScheduleMessage: agents_schedule_message || "",
    agentsOutOfScheduleDialog: agents_schedule_dialog || null,
    scheduledChatClosingDialog: closing_dialog,
    scheduledChatClosingDialogType: closing_dialog_type,
    scheduledChatClosingDialogAt: closing_dialog_schedule,
    inactivityDialog: inactivity_dialog,
    inactivityDialogAt: inactivity_dialog_schedule,
    ...(agents_schedule
      ? { agentsSchedule: agents_schedule && JSON.parse(agents_schedule) }
      : {}),
    livecardSchedule: livecard_schedule,
    maxChatAssigned: max_chat_assigned,
    chatAssignmentType: chat_assignment_type,
    autoAssignChatUserTypes: auto_assign_chat_user_types,
    ednaBotEnabled: edna_bot_enabled,
    queuePositionEnabled: queue_position_enabled,
    queuePositionDialog: queue_position_dialog,
    enableAutoAssignRequestOnNextDay: auto_assign_request_on_next_day_enabled,
    chatTransferredTimeout: chat_transferred_timeout,
    autoAssignDealUserTypes: auto_assign_deal_user_types,
    autoAssignRoundRobinChat: auto_assign_chat_round_robin_chat,
    roundRobinChatTimeout: round_robin_chat_timeout,
    disableACMForChatTransfer: disable_acm_for_chat_transfer,
    disableCCDForChatTransfer: disable_ccd_for_chat_transfer,
    whatsappEnabled: whatsapp_enabled,
    whatsappProvider: whatsapp_provider,
    voiceCallEnabled: voice_call_enabled,
    voiceCallProvider: voice_call_provider,
  },
  featureFlags: feature_flags || [],
  hasVideoCallEnabled: has_video_call_enabled,
});

export const chatAdapter = ({
  id,
  profile_pic,
  first_name,
  last_name,
  from_channel,
  updated_at,
  new_messages,
  livechat,
  flag,
  current_agent,
  data,
  labels,
  last_msg_date,
  user_last_msg_date,
  request_status,
  previous_agent,
  disable_video_call,
  is_starred,
  display_as_contact,
  contact_created_at,
  primary_contact_for,
  blocked,
  priority,
  archived,
  has_interaction_with_agent,
  last_live_card_id,
  delayed_disconnect,
}: any): Chat => {
  const senderId = data
    ? JSON.parse(data)?.user_data?.user_data?.fb_id
    : undefined;

  const model: Chat = {
    id,
    senderId,
    image: profile_pic,
    firstName: first_name,
    lastName: last_name,
    channel: from_channel,
    newMessagesCount: new_messages,
    hasRequestedAgent: flag === 0,
    isConnected: livechat === 0,
    userConnected: current_agent,
    starred: is_starred,
    lastMessage: last_msg_date
      ? DateTime.fromISO(last_msg_date).toJSDate()
      : undefined,
    userLastMessage: user_last_msg_date
      ? DateTime.fromISO(user_last_msg_date).toJSDate()
      : undefined,
    contactCreatedAt: contact_created_at
      ? DateTime.fromFormat(contact_created_at, "yyyy-MM-dd HH:mm:ss", {
          zone: "utc",
        })
          .toLocal()
          .toJSDate()
      : undefined,
    updatedAt: DateTime.fromISO(last_msg_date || updated_at).toJSDate(),
    data: data ? JSON.parse(data)?.user_data?.user_data : undefined,
    labels: labels ? JSON.parse(labels) : undefined,
    requestStatus: request_status,
    previousUserConnected: previous_agent,
    disableVideoCall: disable_video_call,
    isContact: display_as_contact,
    merged: primary_contact_for ? JSON.parse(primary_contact_for) : null,
    isBlocked: blocked,
    priority,
    archived,
    hasInteractionWithAgent: has_interaction_with_agent,
    lastLiveCardId: last_live_card_id,
    delayedDisconnect: delayed_disconnect,
  };

  if (model.data) {
    if (model.data.test) delete model.data.test;

    Object.entries(model.data).forEach(([k, v]) => {
      if (typeof v !== "string" && model.data) {
        model.data[k] = JSON.stringify(v);
      }
    });
  }

  return model;
};

export const messageAdapter = ({
  id,
  text,
  delivery_status,
  error_code,
  error_message,
  sender_id,
  sender_type,
  created_at,
  first_name,
  last_name,
  meta_type,
}: any): Message => {
  const isFromUser = sender_type === 0;
  // eslint-disable-next-line no-control-regex
  const data = JSON.parse(text.replace(/[\x00-\x1F\x7F-\x9F]/g, ""));

  let textMessage = "";
  let reaction;
  let isDeleted = false;
  let attachments;
  let attachment;
  const confidential = data?.confidential;

  if (isFromUser) {
    if (data["entry"][0]["messaging"][0]["message"]) {
      textMessage = data["entry"][0]["messaging"][0]["message"]["text"];
      reaction = data["entry"][0]["messaging"][0]["message"]?.["reaction"];
      attachments = data["entry"][0]["messaging"][0]["message"]["attachments"];
      attachment = data["entry"][0]["messaging"][0]["message"]["attachment"];
      isDeleted =
        data["entry"][0]["messaging"][0]["message"]["is_deleted"] || false;
    } else if (data["entry"][0]["messaging"][0]["postback"]) {
      textMessage = data["entry"][0]["messaging"][0]["postback"]["title"];
    } else if (
      data["entry"][0]["messaging"][0]["referral"]?.["source"] === "ADS"
    ) {
      attachment = {
        type: "referral",
        payload: {
          url:
            data["entry"][0]["messaging"][0]["referral"]?.[
              "ads_context_data"
            ]?.["photo_url"] ||
            data["entry"][0]["messaging"][0]["referral"]?.[
              "ads_context_data"
            ]?.["video_url"],
          headline:
            data["entry"][0]["messaging"][0]["referral"]?.[
              "ads_context_data"
            ]?.["ad_title"],
          source: `https://www.facebook.com/${
            data["entry"][0]["messaging"][0]["referral"]?.[
              "ads_context_data"
            ]?.["post_id"] ||
            data["entry"][0]["messaging"][0]["referral"]?.["ad_id"]
          }`,
          type: "Post",
        },
      };
    }
  } else {
    textMessage = data?.message?.text || "";
    reaction = data?.message?.reaction;
  }

  if (!attachment && data?.message?.attachment) {
    attachment = data?.message?.attachment;
  }

  return {
    id,
    text: textMessage,
    reaction,
    attachment,
    attachments,
    firstName: first_name,
    lastName: last_name,
    deliveryStatus: delivery_status,
    errorCode: error_code,
    errorMessage: error_message,
    senderId: sender_id,
    isFromUser,
    isDeleted,
    metaType: meta_type,
    createdAt: DateTime.fromISO(created_at).toJSDate(),
    confidential,
    meta: data?.meta,
  };
};

const campaignAdapter = ({ id, created_at, name, channel }: any): Campaign => ({
  id,
  createdAt: DateTime.fromISO(created_at).toJSDate(),
  name,
  channel,
});

export const linkBot = async (id: number, key: string, password: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/link-bot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const { error, ...bot } = await response.json();

    if (error) {
      return null;
    }

    return botAdapter(bot);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getChats = async (
  signal: any,
  id: number,
  key: string,
  password: string,
  term?: string,
  label?: string,
  tab: ChatTab = "all",
  archived?: boolean,
  pageSize?: number,
  userId?: string,
  liveChatFilters?: LiveChatFilters,
  dates?: string[],
  sort?: string,
  channel?: ChatChannel,
  assignee?: string,
  nonBizHour?: number,
  priority?: number,
  currentAgentId?: string,
  userType?: number,
  campaignId?: number,
  boardId?: number,
  boardColumnId?: number,
  boardAssigneeId?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats`,
      {
        signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          term,
          label,
          archived,
          tab,
          pageSize,
          userId,
          liveChatFilters,
          dates,
          sort,
          channel,
          assignee,
          nonBizHour,
          priority,
          currentAgentId,
          userType,
          campaignId,
          boardId,
          boardColumnId,
          boardAssigneeId,
        }),
      }
    );

    const chats = await response.json();

    return chats?.map(chatAdapter) as Chat[];
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getMyChats = async (
  id: number,
  key: string,
  password: string,
  userId: string,
  liveChatFilters?: LiveChatFilters
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/user/${userId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, liveChatFilters }),
      }
    );

    const chats = await response.json();

    return chats?.map(chatAdapter) as Chat[];
  } catch (error) {
    logError(error);
    return [];
  }
};

export const getChat = async (
  id: number,
  key: string,
  password: string,
  chatId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const chat = await response.json();

    return chatAdapter(chat);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getChatMessages = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  pageSize?: number,
  liveChatFilters?: LiveChatFilters
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/messages`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, pageSize, liveChatFilters }),
      }
    );

    const messages = await response.json();

    return messages?.map(messageAdapter) as Message[];
  } catch (error) {
    logError(error);
    return [];
  }
};

export const markChatMessagesAsRead = async (
  id: number,
  key: string,
  password: string,
  chatId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/messages/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const setStarredChat = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  starred: boolean,
  priority?: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/star`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, starred, priority }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const connectToChat = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  userToConnect: User
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/connect`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, userToConnect }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const disconnectFromChat = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  userToDisconnect: User,
  delayedDisconnect: number,
  skipClosingDialog?: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/disconnect`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          userToDisconnect,
          delayedDisconnect,
          skipClosingDialog,
        }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const disconnectAllFromChat = async (
  id: number,
  key: string,
  password: string,
  chatIds?: number[],
  dateRange?: string[],
  liveCardId?: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/disconnect`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, chatIds, dateRange, liveCardId }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const postPinMessage = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  messageId: number,
  duration: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/pin/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, messageId, duration }),
      }
    );

    const { chat } = await response.json();

    return chatAdapter(chat);
  } catch (error) {
    logError(error);
    return false;
  }
};

export const postUnpinMessage = async (
  id: number,
  key: string,
  password: string,
  chatId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/unpin/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const postConfidentialMessage = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  messageId: number,
  remove?: boolean,
  liveChatFilters?: LiveChatFilters
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/confidential/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          messageId,
          remove,
          liveChatFilters,
        }),
      }
    );

    const { success, message } = await response.json();

    if (!success && message) {
      throw new Error(message);
    }

    return !!success;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const sendNewMessageToChat = async (
  id: number,
  key: string,
  password: string,
  senderId: string,
  liveCardId: number,
  templateMeta: Record<string, any>,
  userId: string,
  saveAsContact?: boolean,
  firstName?: string,
  lastName?: string,
  type?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/new-message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          senderId,
          liveCardId,
          templateMeta,
          userId,
          saveAsContact,
          firstName,
          lastName,
          type,
        }),
      }
    );

    const { success, status, response: r } = await response.json();
    if (!success && status === 403) {
      throw new Error(r);
    }
    return chatAdapter(r);
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const sendMessageToChat = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  from: string,
  payload: string | Blob
) => {
  try {
    let body: string | FormData;

    if (typeof payload === "string") {
      body = JSON.stringify({ key, password, text: payload, from });
    } else {
      body = new FormData();
      body.append("key", key);
      body.append("password", password);
      body.append("from", from);
      if (payload.type === "emailTemplate") {
        body.append("file", (payload as any).id);
      } else if (payload.type === "contentTemplate") {
        body.append("file", JSON.stringify((payload as any).meta));
      } else {
        body.append("file", payload, (payload as any).name);
      }
      body.append("type", payload.type);
    }

    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/messages/send`,
      {
        method: "POST",
        headers:
          typeof payload === "string"
            ? {
                "Content-Type": "application/json",
              }
            : {},
        body,
      }
    );

    const { success, status, response: r } = await response.json();
    if (!success && status === 403) {
      throw new Error(r);
    }

    return r.map((m: any) => messageAdapter(m));
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const saveChatData = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/data-update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, data }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const setChatLabels = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  label: string,
  type: "add" | "remove",
  deadline?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/labels/set`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          label,
          type,
          deadline,
        }),
      }
    );

    const { chat } = await response.json();

    return chatAdapter(chat);
  } catch (error) {
    logError(error);
  }
};

export const setChatArchiveStatus = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  archived: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/archive`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, archived }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const saveBotAgentsSchedule = async (
  id: number,
  key: string,
  password: string,
  message?: string,
  dialog?: number,
  schedule?: Schedule,
  livecardSchedule?: Record<string, any>,
  timezone?: string,
  scheduledChatClosingDialog?: number,
  scheduledChatClosingDialogType?: string,
  scheduledChatClosingDialogAt?: number,
  maxChatAssigned?: number,
  chatAssignmentType?: string,
  autoAssignChatUserTypes?: number[],
  queuePositionEnabled?: boolean,
  queuePositionDialog?: number,
  enableAutoAssignRequestOnNextDay?: boolean,
  chatTransferredTimeout?: number,
  autoAssignDealUserTypes?: number[],
  autoAssignRoundRobinChat?: boolean,
  roundRobinChatTimeout?: number,
  disableACMForChatTransfer?: boolean,
  disableCCDForChatTransfer?: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/agents-schedule/set`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          message,
          dialog,
          schedule,
          livecardSchedule,
          timezone,
          scheduledChatClosingDialog,
          scheduledChatClosingDialogType,
          scheduledChatClosingDialogAt,
          maxChatAssigned,
          chatAssignmentType,
          autoAssignChatUserTypes,
          queuePositionEnabled,
          queuePositionDialog,
          enableAutoAssignRequestOnNextDay,
          chatTransferredTimeout,
          autoAssignDealUserTypes,
          autoAssignRoundRobinChat,
          roundRobinChatTimeout,
          disableACMForChatTransfer,
          disableCCDForChatTransfer,
        }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getAssignedChatsByAgent = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/agents/chats-count`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return r.map((m: any) => ({
      current_agent: m.current_agent,
      ongoingChats: m.ongoingChats,
      ongoingCalls: m.ongoingCalls,
    })) as UserAssignedChat[];
  } catch (error) {
    logError(error);
    return null;
  }
};

export const assignChatToAgent = async (
  id: number,
  key: string,
  password: string,
  userId: string,
  agentId: string,
  chatId: number,
  autoAssign: boolean,
  userToConnect?: User
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/agent/${agentId}/chat/${chatId}/assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          userId,
          autoAssign,
          userToConnect,
        }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const assignChatToCard = async (
  id: number,
  key: string,
  password: string,
  userId: string,
  cardId: string,
  chatId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/card/${cardId}/chat/${chatId}/assign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, userId }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const respondChatAssignment = async (
  id: number,
  key: string,
  password: string,
  agentId: string,
  chatId: number,
  status: RequestStatus,
  userToConnect: User
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/agent/${agentId}/chat/${chatId}/assign-response`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, status, userToConnect }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const sendVideoCallRequest = async (
  id: number,
  agentId: string,
  chatId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/${chatId}/connect/video`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ agent: agentId, fromAgent: true }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return messageAdapter(r[0]);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const respondVideoRequest = async (
  id: number,
  messageId: number,
  status: RequestStatus
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/video/response/${messageId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return messageAdapter(r);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const updateVideoCallAbility = async (
  id: number,
  agentId: string,
  disable: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/video/ability`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ botId: id, agentId, disable }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const callBotmateSkill = async (
  id: number,
  skill: string,
  text: string | undefined,
  messages: any[]
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/botmate/skill`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ skill, text, messages }),
      }
    );

    const { success, message: r } = await response.json();

    if (!success) {
      return null;
    }

    return r;
  } catch (error) {
    logError(error);
    return null;
  }
};

export async function getMetric<T = MetricData>(
  botId: number,
  name: string,
  channel?: string,
  from?: string,
  to?: string,
  agents?: string[],
  card?: number,
  customParams?: Record<string, string>
): Promise<T[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/metric/${name}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          name,
          channel,
          from,
          to,
          agents,
          card,
          ...customParams,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as T[];
  } catch (error) {
    logError(error);
    throw error;
    // return [];
  }
}

export const getSourceInfo = async (
  botId: number,
  agents?: string[],
  card?: number,
  from?: string,
  to?: string,
  label?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/sources-performance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          agents,
          card,
          from,
          to,
          label,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as SourceInfo[];
  } catch (error) {
    logError(error);
    throw error;
    // return [];
  }
};

export const getCompletedCampaignsCount = async (
  botId: number,
  key: string,
  password: string,
  from?: string,
  to?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${botId}/campaigns/completed-stats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          from,
          to,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return [];
    }

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getTeamPerformance = async (
  botId: number,
  from?: string,
  to?: string,
  agents?: string[],
  card?: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/team-performance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          from,
          to,
          agents,
          card,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as TeamPerformanceInfo[];
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getAgentStats = async (
  botId: number,
  params: Record<string, any>
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/agent-stats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          ...params,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getAbandonedStats = async (
  botId: number,
  params: Record<string, any>
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/abandoned-stats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          ...params,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getTeamVolume = async (
  botId: number,
  from?: string,
  to?: string,
  agents?: string[],
  card?: number,
  ratingType?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/team-volume`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          from,
          to,
          agents,
          card,
          ratingType,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as TeamPerformanceInfo[];
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getRatingAnalysis = async (
  botId: number,
  from?: string,
  to?: string,
  view?: string,
  ratingType?: string,
  rating?: number,
  agentId?: string,
  agentIds?: string[]
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/rating-analysis`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          from,
          to,
          view,
          rating,
          ratingType,
          agentId,
          agentIds,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as any[];
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getAvgSatisfactionRate = async (
  botId: number,
  filters?: Record<string, any>
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/avg-satisfaction-rate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          ...filters,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data as any[];
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getChatActivities = async (botId: number, chatId: number) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/analytics/chat-activities`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          botId,
          chatId,
        }),
      }
    );

    const { success, data } = await response.json();

    if (!success) {
      return [];
    }

    return data.map(
      (m: any) =>
        ({
          userId: m.user_id,
          type: m.type,
          createdAt: DateTime.fromISO(m.created_at).toJSDate(),
        } as ChatActivity)
    );
  } catch (error) {
    logError(error);
    return [];
  }
};

export const sendEvents = async (
  id: number,
  agentId: string,
  event: string,
  chatId?: number,
  extra?: Record<string, string>
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/events`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ botId: id, agentId, event, chatId, extra }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getLiveStream = async (id: number) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/stream`,
      { method: "GET" }
    );

    const { success, response: data } = await response.json();

    if (!success) {
      return null;
    }

    return data as LiveStream;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const createLiveStream = async (
  id: number,
  key: string,
  password: string,
  liveCardId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/stream`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, liveCardId }),
      }
    );

    const { success, response: data } = await response.json();

    if (!success) {
      return null;
    }

    return data as LiveStream;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const disableLiveStream = async (
  id: number,
  key: string,
  password: string,
  streamId: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/stream/${streamId}/stop`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getLiveStreamConnection = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/stream/connection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success, response: data } = await response.json();

    if (!success) {
      return null;
    }

    return data as LiveStream;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getLiveStreamStatus = async (id: number, stream: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/stream/${stream}/status`,
      { method: "GET" }
    );

    const { success, response: data } = await response.json();

    if (!success) {
      return null;
    }

    return data as LiveStream;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getLiveChatCards = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/cards/list/live-chat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success, response: data } = await response.json();

    if (!success) {
      return null;
    }

    return data as LiveChatCard[];
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getChatsRequestedCount = async (
  id: number,
  key: string,
  password: string,
  liveChatFilters?: LiveChatFilters
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/count/requested`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, liveChatFilters }),
      }
    );

    const data = await response.json();

    if (!data) {
      return [];
    }

    return data.map((m: any) => m.id) as number[];
  } catch (error) {
    logError(error);
    return [];
  }
};

export const getAllChatsCount = async (
  id: number,
  key: string,
  password: string,
  userId?: string,
  liveChatFilters?: LiveChatFilters,
  assigneeId?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/count/all`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          userId,
          liveChatFilters,
          assigneeId,
        }),
      }
    );

    const data = await response.json();

    if (!data) {
      return false;
    }

    return data;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getCampaigns = async (
  id: number,
  key: string,
  password: string,
  page?: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, page }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return [];
    }

    return r;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getCampaignStats = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/stats`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return {};
    }

    return r;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const postCampaign = async (
  id: number,
  key: string,
  password: string,
  name: string,
  channel: ChatChannel,
  data: string,
  campaignDataType: CampaignDataType,
  file?: File,
  scheduleFor?: string
) => {
  try {
    const body = new FormData();
    body.append("key", key);
    body.append("password", password);
    body.append("name", name);
    body.append("data", data);
    body.append("dataType", campaignDataType);
    body.append("channel", channel);
    if (file) {
      body.append("file", file);
    }
    body.append("scheduleFor", scheduleFor || "");
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/create`,
      {
        method: "POST",
        body,
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return campaignAdapter(r);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const postEndCampaignStatus = async (
  id: number,
  key: string,
  password: string,
  campaingId: number
) => {
  try {
    const body = new FormData();
    body.append("key", key);
    body.append("password", password);
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/${campaingId}/end`,
      {
        method: "POST",
        body,
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      throw new Error(r);
    }
  } catch (error) {
    logError(error);
  }
};

export const getCampaignResults = async (
  id: number,
  key: string,
  password: string,
  campaignId: number,
  pageSize?: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/${campaignId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, pageSize }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return {
      campaign: r.campaign,
      messages: r.messages.map(messageAdapter) as Message[],
    };
  } catch (error) {
    logError(error);
    return null;
  }
};

export const postCampaignTemplateNotification = async (
  id: number,
  key: string,
  password: string,
  userName: string,
  templateId: string,
  type: string
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/templates/notify-admin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, userName, templateId, type }),
      }
    );
  } catch (error) {
    logError(error);
    return null;
  }
};

export const postTemplateToTwilio = async (
  username: string,
  password: string,
  body: any
) => {
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  try {
    const response = await fetch(`https://content.twilio.com/v1/Content`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${base64Credentials}`,
      },
      body: JSON.stringify({ ...body }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postTemplateStatusTwilio = async (
  username: string,
  password: string,
  sid: string
) => {
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  try {
    const response = await fetch(
      `https://content.twilio.com/v1/Content/${sid}/ApprovalRequests`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${base64Credentials}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postTemplateToTwilioForApproval = async (
  username: string,
  password: string,
  sid: string,
  body: any
) => {
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  try {
    const response = await fetch(
      `https://content.twilio.com/v1/Content/${sid}/ApprovalRequests/whatsapp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${base64Credentials}`,
        },
        body: JSON.stringify({ ...body }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const deleteTemplateFromTwilio = async (
  username: string,
  password: string,
  sid: string
) => {
  const credentials = `${username}:${password}`;
  const base64Credentials = btoa(credentials);
  try {
    const response = await fetch(
      `https://content.twilio.com/v1/Content/${sid}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Basic ${base64Credentials}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getContacts = async (
  id: number,
  key: string,
  password: string,
  term?: string,
  ids?: number[],
  page = 1,
  size = 10,
  orderBy?: string,
  order?: "asc" | "desc",
  label?: string,
  filters?: any,
  all?: boolean,
  liveChatFilters?: LiveChatFilters,
  assignee?: string,
  dateRange?: string[]
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          term,
          ids,
          size,
          orderBy,
          order,
          label,
          filters,
          liveChatFilters,
          assignee,
          all,
          dateRange,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return [];
    }

    return ids || size === 0 || all
      ? (r?.map(chatAdapter) as Chat[])
      : {
          page: r?.current_page as number,
          total: r?.total as number,
          last_page: r?.last_page as number,
          data: r?.data?.map(chatAdapter) as Chat[],
        };
  } catch (error) {
    logError(error);
    return [];
  }
};

export const setContact = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  value: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/${chatId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, value: value ? 1 : 0 }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const findChatBySenderId = async (
  id: number,
  key: string,
  password: string,
  senderId: string,
  channel?: ChatChannel
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/search-by-sender-id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          sender_id: senderId,
          channel,
        }),
      }
    );

    const { chat } = await response.json();

    return chat && chatAdapter(chat);
  } catch (error) {
    logError(error);
    return false;
  }
};

export const findContact = async (
  id: number,
  key: string,
  password: string,
  chatId: string,
  channel: ChatChannel
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/find`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          id: chatId,
          channel,
        }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const addContact = async (
  id: number,
  key: string,
  password: string,
  firstName: string,
  lastName: string,
  chatId?: string,
  channel?: ChatChannel,
  otherChannel?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          id: chatId,
          firstName,
          lastName,
          channel,
          otherChannel,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return null;
    }

    return chatAdapter(r);
  } catch (error) {
    logError(error);
    return null;
  }
};

export const postCreateChatRoom = async (
  id: number,
  key: string,
  password: string,
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/createRoom`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          ...data,
        }),
      }
    );

    const { success, response: r, error } = await response.json();

    if (!success) {
      throw Error(error);
    }

    return chatAdapter(r);
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postImportContact = async (
  id: number,
  key: string,
  password: string,
  file: File,
  fields: any,
  labelsMap: any
) => {
  try {
    const body = new FormData();
    body.append("key", key);
    body.append("password", password);
    body.append("file", file);
    body.append("fields", JSON.stringify(fields));
    if (labelsMap) {
      body.append("labelsMap", JSON.stringify(labelsMap));
    }

    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/import`,
      {
        method: "POST",
        body,
      }
    );

    await response.json();
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const mergeContacts = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  contacts: number[]
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/${chatId}/merge`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, contacts }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getContactsCount = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/contacts/count`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return 0;
    }

    return r as number;
  } catch (error) {
    logError(error);
    return 0;
  }
};

export const getTodayChatsCount = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/today-count`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return 0;
    }

    return r as number;
  } catch (error) {
    logError(error);
    return 0;
  }
};

export const getRatingScore = async (
  id: number,
  key: string,
  password: string,
  ratingType: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/rating/score`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          ratingType,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return 0;
    }

    return r as number;
  } catch (error) {
    logError(error);
    return 0;
  }
};

export const setBlockUserStatus = async (
  id: number,
  key: string,
  password: string,
  chatId: number,
  value: boolean
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/block/${chatId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, value: value ? 1 : 0 }),
      }
    );

    const { success } = await response.json();

    return !!success;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const getProfile = async (id: number, userId: number) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/profile/${userId}/update`
    );

    const { success, data } = await response.json();

    return success ? chatAdapter(data) : null;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getBotSettings = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/settings?key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { success, settings } = await response.json();

    if (success) {
      return settings;
    }
    return false;
  } catch (error) {
    logError(error);
    return false;
  }
};

export const postBotSettings = async (
  id: number,
  key: string,
  password: string,
  type: string,
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/settings`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, type, data }),
      }
    );

    const { success, message } = await response.json();

    if (!success) {
      throw new Error(message);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const syncUserToChathub = async (
  id: number,
  key: string,
  password: string,
  user: User
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/sync-chat-user`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, user }),
      }
    );

    const { success, message } = await response.json();

    if (!success) {
      throw new Error(message);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const generateEmbedding = async (id: string, data: EmbeddingData) => {
  try {
    const token = await auth.getBearerToken();
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/generate-embedding`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data }),
      }
    );

    const { success, message } = await response.json();

    if (!success) {
      throw new Error(message);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const removeEmbedding = async (id: string, data: EmbeddingData) => {
  try {
    const token = await auth.getBearerToken();
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/remove-embedding`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ data }),
      }
    );

    const { success, message } = await response.json();

    if (!success) {
      throw new Error(message);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const callEdnaBot = async (
  id: string,
  message: string,
  embeddingTypes: string[],
  meta: any,
  lang?: string
) => {
  try {
    const token = await auth.getBearerToken();

    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/call-edna-bot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ message, embeddingTypes, meta, lang: "es" }),
      }
    );

    const { success, response: r } = await response.json();

    if (success) {
      return r;
    }
    throw new Error("Error");
  } catch (error) {
    logError(error);
    return "Something went wrong";
  }
};

export const getAITeams = async (id: string) => {
  try {
    const token = await auth.getBearerToken();

    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/ai-teams`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return {};
    }

    return r;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const callAITeam = async (
  id: string,
  chatId: string,
  aITeamId: string,
  team: string,
  message: string,
  messages?: any[],
  userId?: string
) => {
  try {
    const token = await auth.getBearerToken();
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/ai-teams/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          chatId,
          aITeamId,
          message,
          team,
          messages,
          userId,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (success) {
      return r;
    }
    throw new Error("Error");
  } catch (error) {
    logError(error);
    return "Something went wrong";
  }
};

export const getErrorLogs = async (
  id: number,
  key: string,
  password: string,
  page = 1,
  size = 10,
  filters?: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/error-log/paginate?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          size,
          filters,
        }),
      }
    );

    const { response: r } = await response.json();

    return {
      page: r?.current_page as number,
      total: r?.total as number,
      last_page: r?.last_page as number,
      data: r?.data as any,
    };
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const storeErrorLogs = async (
  id: number,
  key: string,
  password: string,
  error: string,
  stack: string
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/error-log`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          error,
          stack,
        }),
      }
    );
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const deleteErrorLogs = async (
  id: number,
  key: string,
  password: string,
  errorId: number
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/error-log/${errorId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const notifyUserForNewTask = async (
  id: number,
  key: string,
  password: string,
  email: string,
  liveCardId: string
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/chats/tasks/notify-user`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, email, liveCardId }),
      }
    );
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getHelpCenterConfig = async (workspaceId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getHelpCenterConfig`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getHelpCenterCollections = async (workspaceId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getHelpCenterCollections`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getHelpCenterCollection = async (
  workspaceId: string,
  collectionId: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getHelpCenterCollection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, collectionId },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getHelpCenterArticle = async (
  workspaceId: string,
  articleId: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getArticle`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, articleId },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getHelpCenterSearchArticles = async (
  workspaceId: string,
  search: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getHelpCenterSearchArticles`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, search },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflowCategories = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow-category?key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const createWorkflowCategory = async (
  id: number,
  key: string,
  password: string,
  name: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow-category`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          name,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const updateWorkflowCategory = async (
  id: number,
  key: string,
  password: string,
  workflowCategoryId: number,
  name: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow-category/${workflowCategoryId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          name,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const deleteWorkflowCategory = async (
  id: number,
  key: string,
  password: string,
  workflowCategoryId: number
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow-category/${workflowCategoryId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflowNodes = async (
  id: number,
  key: string,
  password: string,
  lang: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/nodes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          lang,
        }),
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflows = async (
  id: number,
  key: string,
  password: string,
  categoryId?: number,
  filters?: any
) => {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_CHATHUB_APP_URL
      }/api/${id}/workflow?categoryId=${categoryId}&key=${key}&password=${password}&archived=${
        filters?.archived ? 1 : 0
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflowEvents = async (
  id: number,
  key: string,
  password: string,
  workflowId?: number,
  page = 1
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/${workflowId}/event?page=${page}&key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflowLogs = async (
  id: number,
  key: string,
  password: string,
  workflowId?: number,
  page = 1
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/${workflowId}/log?page=${page}&key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getWorkflow = async (
  id: number,
  key: string,
  password: string,
  workflowId: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/${workflowId}?key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const createWorkflow = async (
  id: number,
  key: string,
  password: string,
  name: string,
  categoryId: number,
  userId: string,
  status: string,
  isActive: boolean,
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          name,
          categoryId,
          userId,
          status,
          isActive,
          data,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const updateWorkflow = async (
  id: number,
  key: string,
  password: string,
  workflowId: number,
  name: string,
  categoryId: number,
  userId: string,
  status: string,
  isActive: boolean,
  archived: boolean,
  updatedBy: string,
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/${workflowId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          name,
          categoryId,
          userId,
          status,
          isActive,
          archived,
          updatedBy,
          data,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const deleteWorkflow = async (
  id: number,
  key: string,
  password: string,
  workflowId: number
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/${workflowId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const triggerWorkflow = async (
  id: number,
  key: string,
  password: string,
  workflowTriggerKey: string,
  workflowMeta: string
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/workflow/trigger`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          workflowTriggerKey,
          workflowMeta,
        }),
      }
    );
  } catch (error) {
    logError(error);
    return null;
  }
};

export const sendFCM = async (
  id: number,
  key: string,
  password: string,
  userIds: string[],
  title: string,
  body: string
) => {
  try {
    await fetch(`${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/send-fcm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key,
        password,
        userIds,
        title,
        body,
      }),
    });
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getKpiNames = async (
  id: number,
  key: string,
  password: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/analytics-kpi/name?key=${key}&password=${password}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const getKpis = async (
  id: number,
  key: string,
  password: string,
  filters?: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/analytics-kpi/all`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          filters,
        }),
      }
    );

    const { response: r } = await response.json();

    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const createKpi = async (
  id: number,
  key: string,
  password: string,
  name: string,
  createdByUserId: string,
  target: number,
  symbol?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/analytics-kpi`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          name,
          createdByUserId,
          target,
          symbol,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const updateKpi = async (
  id: number,
  key: string,
  password: string,
  kpiId: number,
  target: number
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/analytics-kpi/${kpiId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          target,
        }),
      }
    );

    const { response: r } = await response.json();
    return r;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const destroyKpi = async (
  id: number,
  key: string,
  password: string,
  kpiId: number
) => {
  try {
    await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/analytics-kpi/${kpiId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
        }),
      }
    );
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const addBotToFunction = async (
  id: string,
  botId: string,
  botKey: string,
  botPassword: string
) => {
  try {
    const token = await auth.getBearerToken();
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/add-bot`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          botId,
          botKey,
          botPassword,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const loadCalendarSetting = async (
  workspaceId: string,
  eventId: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/getCalendarSetting`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, eventId },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const createBooking = async (workspaceId: string, booking: Booking) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/createBooking`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, booking },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const searchContactInFirebase = async (
  workspaceId: string,
  id: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/searchChat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: { workspaceId, id },
        }),
      }
    );

    const { result } = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postFBRedirectCallback = async (id: string, data: any) => {
  try {
    const token = await auth.getBearerToken();
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/redirect-callback/facebook`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );

    const result = await response.json();

    if (!result.success) {
      throw new Error("Something went wrong");
    }

    return result.data;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postWorkspaceEvent = async (id: string, type: string) => {
  try {
    const token = await auth.getBearerToken();

    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/workspace/${id}/events/notify`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ type }),
      }
    );

    const { success, response: r } = await response.json();

    if (success) {
      return r;
    }
    throw new Error("Error");
  } catch (error) {
    logError(error);
    return "Something went wrong";
  }
};

export const searchCampaigns = async (
  id: number,
  key: string,
  password: string,
  search?: string
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/campaigns/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          password,
          search,
        }),
      }
    );

    const { success, response: r } = await response.json();

    if (!success) {
      return [];
    }

    return r as Campaign[];
  } catch (error) {
    logError(error);
    return [];
  }
};

export const postTestIMAP_SMTP = async (
  id: number,
  key: string,
  password: string,
  type: "imap" | "smtp",
  data: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/test/imap-smtp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, type, data }),
      }
    );

    const { message } = await response.json();
    return message;
  } catch (error) {
    logError(error);
    throw error;
  }
};

export const postTemplateToMetaWhatsapp = async (
  id: number,
  key: string,
  password: string,
  path: string,
  method: string,
  body: any
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${id}/meta-template`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ key, password, path, method, body }),
      }
    );
    const res = await response.json();

    if (response.status === 400) {
      throw new Error(
        res?.error?.error_user_msg ?? res?.error?.error_user_title
      );
    }

    return res;
  } catch (error) {
    logError(error);
    throw error;
  }
};
