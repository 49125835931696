import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../components";

import locale from "../constants/locale";

type Props = {
  isOpen: boolean;
  itemName: string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

const DeleteModal = ({ isOpen, onCancel, onConfirm, itemName }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t(locale.delete)}
      onClose={onCancel}
      primaryText={t(locale.delete)}
      primaryLoading={isLoading}
      secondaryText={t(locale.cancel)}
      onPrimary={handleConfirm}
      onSecondary={onCancel}
    >
      {t(locale.askDeleteConfirmation, { name: itemName })}
    </Modal>
  );
};

export default DeleteModal;
