import { useTranslation } from "react-i18next";
import {
  BoardItemCard,
  ticketPriorityColorMap,
  TicketStatus,
  typeTypeColorMap,
} from "../../../../models";
import { EdnaMessageParser } from "../../../../models/EdnaChat";
import { useStore } from "../../../../stores";
import Mentionify from "../../../mentionify/Mentionify";
import { DateTime } from "luxon";
import { useMemo } from "react";
import clsx from "clsx";
import styles from "./RenderTicket.module.scss";
import locale from "../../../../constants/locale";
import { FallbackImage } from "../../..";
import routes from "../../../../constants/routes";
import { useHistory } from "react-router";

export const RenderTicket = ({ message }: { message: EdnaMessageParser }) => {
  return (
    <>
      <Mentionify>{message.text}</Mentionify>
      {message.ticket && (
        <ItemComponent
          item={
            {
              id: message.ticket.id,
              uuid: message.ticket.uuid,
              livecardId: message.ticket.livecardId,
              name: message.ticket.name,
              priority: message.ticket.priority,
              dueDate: message.ticket.dueDate
                ? DateTime.fromISO(message.ticket.dueDate as any)
                : undefined,
              type: message.ticket.type,
              assigneeId: message.ticket.assigneeId,
            } as any
          }
        />
      )}
    </>
  );
};

const ItemComponent = ({ item }: { item: BoardItemCard }) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useStore("workspaces");
  const { users } = useStore("users");
  const history = useHistory();

  const assignedUser = useMemo(
    () => users?.find((f) => f.id === item.assigneeId),
    [item.assigneeId, users]
  );

  const isDue =
    item.dueDate &&
    ![TicketStatus.TODO, TicketStatus.DONE].includes(item.status!) &&
    item.dueDate <= DateTime.now();

  const routeTo = () => {
    history.push(
      routes.tickets
        .replace(":workspaceId", String(selectedWorkspace?.id))
        .replace(":functionId", "op")
        .replace(":group", item.livecardId as any) + `?ticketId=${item.uuid}`
    );
  };

  return (
    <div
      className={clsx(
        "is-clickable is-list-item has-background-white mt-4 p-4"
      )}
      onClick={routeTo}
    >
      <div className="is-flex is-justify-content-space-between">
        <strong className={clsx("mr-2", styles.userName)}>{item?.name}</strong>
      </div>
      <p
        className={clsx("subtitle is-7 mb-0", {
          "has-text-danger has-text-weight-semibold": isDue,
        })}
      >
        {item.dueDate ? item.dueDate.toFormat("M/d/yyyy, h:mm a") : "-"}
      </p>

      <div
        className={clsx(
          "is-flex is-align-items-end mt-4 is-justify-content-space-between"
        )}
      >
        <div>
          <span
            className="has-text-weight-semibold has-text-white tag"
            style={{
              background: typeTypeColorMap[(item.type as never) ?? "ticket"],
            }}
          >
            {t(item.type ?? locale.ticket)}
          </span>

          {item.priority && (
            <span
              className="ml-2"
              style={{
                color: ticketPriorityColorMap[item.priority],
                fontWeight: 500,
                fontSize: 12,
              }}
            >
              {t(item.priority)}
            </span>
          )}
        </div>

        {assignedUser && (
          <div className="image is-32x32">
            <FallbackImage
              title={t(locale.assignee) + " " + String(assignedUser?.name)}
              className="is-rounded"
              src={assignedUser?.avatarUrl}
              fallback="/assets/avatar.svg"
              alt={assignedUser?.name || item.assigneeId}
            />
          </div>
        )}
      </div>
    </div>
  );
};
