import { useCallback, useState } from "react";
import { Modal } from "../../..";
import locale from "../../../../constants/locale";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Hub } from "../../../../models";
import { useStore } from "../../../../stores";
import { toast } from "bulma-toast";
import { useHistory } from "react-router";
import routes from "../../../../constants/routes";

const LeaveHubModal = ({ hub }: { hub: Hub }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [leavingHub, setLeavingHub] = useState(false);
  const { selectedWorkspace } = useStore("workspaces");
  const { leaveHub } = useStore("hubs");

  const onLeaveHub = useCallback(async () => {
    setLeavingHub(true);
    try {
      await leaveHub(hub.id!);
      toast({
        message: "Hub leaved successfully",
        type: "is-success",
        dismissible: true,
      });
      history.push(
        routes.teammates
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":userId?", "")
      );
    } catch (error) {
      toast({
        message: "Failed to leave hub",
        type: "is-danger",
        dismissible: true,
      });
    } finally {
      setLeavingHub(false);
    }
  }, [leaveHub, history, hub.id, selectedWorkspace?.id]);

  return (
    <>
      <div className="w-100 has-text-right">
        <button
          onClick={() => setOpen(true)}
          disabled={leavingHub}
          className="button p-0 is-no-box-shadow is-ghost has-text-danger"
        >
          {t(locale.leave)}
        </button>
      </div>
      <Modal
        isOpen={open}
        title={() => (
          <h2 className="title is-5">
            {t(locale.leave)} "{hub.name}"
          </h2>
        )}
        onClose={() => setOpen(false)}
        primaryText={t(locale.confirm)}
        primaryLoading={leavingHub}
        secondaryText={t(locale.cancel)}
        onPrimary={() => {
          setOpen(false);
          onLeaveHub();
        }}
        onSecondary={() => setOpen(false)}
      >
        <span
          className="is-6 subtitle"
          style={{
            fontSize: "12px",
          }}
        >
          {t(locale.hubLeaveConfirmation)}
        </span>
      </Modal>
    </>
  );
};

export default observer(LeaveHubModal);
