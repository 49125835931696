import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import auth from "./auth";
import firebaseApp from "firebase/compat/app";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";
import { Hub, HubMember, User } from "../models";

class HubsStore {
  private db: firebase.firestore.Firestore;
  hubs?: Hub[];
  currentHubUsers?: User[];
  selectedHub?: Hub;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  loadHubs = async () => {
    if (!auth.user) {
      return;
    }

    const snapshot = [];

    const adminSnapshot = await this.db
      .collection("hubs")
      .where("creatorId", "==", auth.user.id)
      .get();
    snapshot.push(...adminSnapshot.docs);

    const memberSnapshot = await this.db
      .collection("hubs")
      .where("memberIds", "array-contains", auth.user.id)
      .get();

    snapshot.push(...memberSnapshot.docs);

    const hubs = snapshot.map((f) => {
      const ref = f.data();
      ref.id = f.id;
      return ref;
    }) as Hub[];

    for (const hub of hubs) {
      const memberSnapshot = await this.db
        .collection("hubs")
        .doc(hub.id)
        .collection("members")
        .get();

      hub.members = memberSnapshot.docs.map((f) => {
        const ref = f.data() as HubMember;
        ref.id = f.id;
        return ref;
      });
    }

    this.setHubs(hubs);
  };

  selectHub = async (hubId: string) => {
    this.selectedHub = this.hubs?.find((f) => f.id === hubId);
    if (this.selectedHub) {
      this.setCurrentHubUsers([]);
      await this.getCurrentHubUsers(
        (this.selectedHub?.members?.map((f) => f.id!) || []).concat(
          this.selectedHub?.creatorId!
        )
      );
    }
  };

  updateOrCreateHub = async (
    name: string,
    members: Hub["members"],
    id?: string
  ) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    let hub: Hub;

    await this.checkMembers(members);

    const newMembers = await this.checkMemberExists(
      members?.filter((f) => !f.id)
    );

    if (id) {
      const ref = this.db.collection("hubs").doc(id);

      const snapshot = await ref.get();
      hub = snapshot.data() as Hub;
      hub.id = snapshot.id;

      const creatorSnapshot = await this.db
        .collection("users")
        .doc(hub.creatorId)
        .get();

      const creator = creatorSnapshot.data() as User;

      if (members?.map((f) => f.email)?.includes(creator.email)) {
        throw new Error("Can't add owner as a member");
      }

      const memberSnapshot = await this.db
        .collection("hubs")
        .doc(hub.id)
        .collection("members")
        .get();

      hub.members = memberSnapshot.docs.map((f) => {
        const ref = f.data() as HubMember;
        ref.id = f.id;
        return ref;
      });

      const deletedMembers = hub.members?.filter(
        (f) => !members?.find((m) => m.id === f.id)
      );

      for (const member of deletedMembers || []) {
        await this.db
          .collection("hubs")
          .doc(hub.id)
          .collection("members")
          .doc(member.id)
          .delete();
      }

      await ref.set(
        {
          name,
          memberIds: members
            ?.filter((f) => f.invitationAccepted)
            ?.map((f) => f.id!),
        },
        { merge: true }
      );

      hub = (await ref.get()).data() as Hub;
      hub.id = id;
    } else {
      if (members?.map((f) => f.email)?.includes(auth.user?.email!)) {
        throw new Error("Can't add yourself as a member");
      }
      const ref = this.db.collection("hubs").doc();

      const hubObj: Hub = {
        name,
        workspaceId: workspaces.selectedWorkspace.id,
        creatorId: auth.user.id,
        memberIds: members
          ?.filter((f) => f.invitationAccepted)
          ?.map((f) => f.id!),
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      await ref.set(hubObj);

      const snapshot = await ref.get();
      hub = snapshot.data() as Hub;
      hub.id = snapshot.id;
    }

    for (const member of newMembers ?? []) {
      await this.db
        .collection("hubs")
        .doc(hub.id)
        .collection("members")
        .doc(member.id)
        .set({
          email: member.email,
          invitedBy: auth.user.id,
          invitationAccepted: false,
          invitationRejected: false,
          createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        });
      await this.inviteMemberToHub(hub, member);
    }

    const memberSnapshot = await this.db
      .collection("hubs")
      .doc(hub.id)
      .collection("members")
      .get();

    hub.members = memberSnapshot.docs.map((f) => {
      const ref = f.data() as HubMember;
      ref.id = f.id;
      return ref;
    });

    if (hub) {
      this.setHubs(
        id
          ? this.hubs?.map((f) => (f.id === id ? hub : f))
          : [...(this.hubs || []), hub]
      );
    }
  };

  checkMembers = async (members: Hub["members"]) => {
    const emails = members?.map((f) => f.email);
    if (!emails?.length) return;

    if (new Set(emails).size !== emails.length) {
      throw new Error("Please remove duplicate emails");
    }
  };

  deleteHub = async (id: string) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    await this.db.collection("hubs").doc(id).delete();

    this.setHubs(this.hubs?.filter((f) => f.id !== id) || []);
  };

  leaveHub = async (id: string) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }
    const userId = auth?.user.id!;

    const ref = this.db.collection("hubs").doc(id);

    const snapshot = await ref.get();
    const hub = snapshot.data() as Hub;

    if (!hub) {
      throw new Error("Hub not found");
    }

    await this.db
      .collection("hubs")
      .doc(id)
      .set(
        {
          memberIds: (hub.memberIds ?? []).filter((f) => f !== userId),
        },
        { merge: true }
      );

    await this.db
      .collection("hubs")
      .doc(id)
      .collection("members")
      .doc(auth.user.id)
      .delete();

    this.setHubs(this.hubs?.filter((f) => f.id !== id) || []);
  };

  checkMemberExists = async (members: Hub["members"]) => {
    if (!members?.length) return;

    const snapshot = await this.db
      .collection("users")
      .where(
        "email",
        "in",
        members.map((f) => f.email)
      )
      .get();

    const users = snapshot.docs.map((f) => {
      const ref = f.data();
      ref.id = f.id;
      return ref;
    }) as User[];

    members.forEach((member) => {
      const user = users.find((f) => f.email === member.email);
      if (!user) {
        throw new Error(`${member.email} not found`);
      }
      member.id = user.id;
    });

    return members;
  };

  getCurrentHubUsers = async (id: string[]) => {
    if (!id.length) return;
    const snapshot = await this.db
      .collection("users")
      .where("id", "in", id)
      .get();

    const members = snapshot.docs.map((f) => {
      const ref = f.data();
      ref.id = f.id;
      return ref;
    }) as User[];

    this.setCurrentHubUsers(members);
  };

  inviteMemberToHub = async (hub: Hub, member: HubMember) => {
    const snapshot = await this.db.collection("users").doc(member.id).get();
    const user = snapshot.data() as User;

    if (!user) {
      throw new Error("User not found");
    }

    for (const workspaceId of user.workspaceIds || []) {
      await notifications.createNotificationToWorkspace(
        workspaceId,
        member.id!,
        NotificationType.HUB_INVITE,
        {
          hubId: hub.id!,
          hubName: hub.name,
        }
      );
    }
  };

  acceptOrRejectHubInvite = async (hubId: string, accept: boolean) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    const ref = this.db.collection("hubs").doc(hubId);

    const snapshot = await ref.get();
    const hub = snapshot.data() as Hub;

    if (!hub) {
      throw new Error("Hub not found");
    }

    const memberSnapshot = await this.db
      .collection("hubs")
      .doc(hubId)
      .collection("members")
      .doc(auth.user.id)
      .get();

    if (!memberSnapshot.exists) {
      throw new Error("Hub member not found");
    }

    await this.db
      .collection("hubs")
      .doc(hubId)
      .set(
        {
          memberIds: (hub.memberIds ?? [])?.concat(auth.user.id),
        },
        { merge: true }
      );

    await this.db
      .collection("hubs")
      .doc(hubId)
      .collection("members")
      .doc(auth.user.id)
      .set(
        {
          invitationAccepted: accept,
          invitationRejected: !accept,
          invitationAcceptedAt: accept
            ? firebaseApp.firestore.FieldValue.serverTimestamp()
            : undefined,
          invitationRejectedAt: accept
            ? undefined
            : firebaseApp.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

    //TODO: send notification to creator of the hub
  };

  private setHubs(hubs?: Hub[]) {
    this.hubs = hubs;
  }

  private setCurrentHubUsers(users: User[]) {
    this.currentHubUsers = users;
  }
}

export default new HubsStore();
