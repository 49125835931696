import { Field, Modal, TagsInputV2 } from "../../../../components";
import { Formik } from "formik";
import { useStore } from "../../../../stores";
import { toast } from "bulma-toast";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import locale from "../../../../constants/locale";
import { Hub, HubMember } from "../../../../models";

export default function HubModal({
  open,
  onClose,
  hub,
}: {
  open: boolean;
  onClose: () => void;
  hub?: Hub;
}) {
  const { t } = useTranslation();
  const { updateOrCreateHub } = useStore("hubs");
  const { user } = useStore("auth");

  const schema = yup.object().shape({
    name: yup.string().required(t(locale.fieldRequired)),
    members: yup
      .array()
      .of(
        yup
          .string()
          .email(t(locale.invalidEmail))
          .required(t(locale.fieldRequired))
      ),
  });

  const isOwner = hub?.creatorId === user?.id;

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        name: hub?.name ?? "",
        members: hub?.members?.map((m) => m.email) ?? [],
      }}
      onSubmit={async (values: any) => {
        try {
          await updateOrCreateHub(
            values.name,
            values.members?.map((email: string) => ({
              email,
              ...hub?.members?.find((f: HubMember) => f.email === email),
            })),
            hub?.id
          );
          onClose();
          toast({
            message: `Hub ${hub ? "updated" : "created"} successfully`,
            type: "is-success",
            dismissible: true,
          });
        } catch (error: any) {
          toast({
            message:
              error?.message ?? `Failed to ${hub ? "update" : "create"} hub`,
            type: "is-danger",
            dismissible: true,
          });
        }
      }}
    >
      {({ isSubmitting, submitForm, values }) => (
        <Modal
          title={`${hub ? "Edit" : "Create"} Hub`}
          isOpen={open}
          onClose={onClose}
          onPrimary={submitForm}
          primaryLoading={isSubmitting}
          primaryText={hub ? "Update" : "Create"}
        >
          <Field
            name="name"
            label={t(locale.name)}
            placeholder="Enter the name of the hub"
            disabled={hub && !isOwner}
          />
          <TagsInputV2
            name="members"
            label={t(locale.members)}
            className="custom-select"
          />
        </Modal>
      )}
    </Formik>
  );
}
