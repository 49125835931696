import {
  Notification,
  parseNotificationText,
  HubInviteNotificationMeta,
} from "../../../../../models/Notification";
import { forwardRef, useState } from "react";
import { useStore } from "../../../../../stores";
import { toast } from "bulma-toast";
import clsx from "clsx";

type Props = {
  notification: Notification;
};

type RefProps = {
  onClick: () => void;
};

export const HubInviteParser = forwardRef<RefProps, Props>(
  ({ notification }, ref) => {
    const { markAsRead } = useStore("notifications");
    const { acceptOrRejectHubInvite } = useStore("hubs");
    const [loading, setLoading] = useState<"accept" | "reject">();

    const meta = notification.meta as HubInviteNotificationMeta;

    const onClick = async () => {
      if (!notification.readAt) {
        await markAsRead(String(notification.id));
      }
    };

    const onAccept = async (accept: boolean) => {
      setLoading(accept ? "accept" : "reject");
      try {
        await acceptOrRejectHubInvite(meta.hubId, accept);
        await onClick();
        toast({
          message: `Invite ${accept ? "accepted" : "rejected"}`,
          type: "is-success",
          dismissible: true,
        });
      } catch (error) {
        console.error(error);
        toast({
          message: `Failed to ${accept ? "accept" : "reject"} invite`,
          type: "is-danger",
          dismissible: true,
        });
      } finally {
        setLoading(undefined);
      }
    };

    return (
      <>
        <div
          className="title mb-2"
          style={{
            fontSize: 12,
          }}
        >
          {parseNotificationText(notification)}
        </div>
        {!notification.readAt && (
          <div className="is-flex">
            <button
              onClick={() => onAccept(true)}
              className={clsx("button is-primary is-small", {
                "is-loading": loading === "accept",
              })}
              disabled={!!loading}
            >
              Accept
            </button>
            <button
              onClick={() => onAccept(false)}
              className={clsx("button is-danger is-small ml-2", {
                "is-loading": loading === "reject",
              })}
              disabled={!!loading}
            >
              Reject
            </button>
          </div>
        )}
      </>
    );
  }
);
