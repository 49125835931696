import { observer } from "mobx-react-lite";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import {
  BoardItemCard,
  ticketPriorityColorMap,
  typeTypeColorMap,
} from "../../../models";
import { FaPlusSquare } from "react-icons/fa";
import { useEffect, useMemo, useState } from "react";
import ItemModal from "./ItemModal";
import locale from "../../../constants/locale";
import { FallbackImage } from "../../../components";
import { useStore } from "../../../stores";

interface SubtasksListProps {
  item: BoardItemCard;
  onClose: () => void;
  paramsSubTicketId?: string;
}

const SubtasksList = ({ item, paramsSubTicketId }: SubtasksListProps) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSubtask, setSelectedSubtask] = useState<BoardItemCard>();

  useEffect(() => {
    if (paramsSubTicketId && !selectedSubtask) {
      const subtask = item.subitems?.find((f) => f.uuid === paramsSubTicketId);
      if (subtask) {
        setSelectedSubtask(subtask);
        setShowModal(true);
      }
    }
  }, [item.subitems, paramsSubTicketId, selectedSubtask]);

  const onAddSubtask = () => {
    setSelectedSubtask(undefined);
    setShowModal(true);
  };
  const onEditSubtask = (subtask: any) => {
    setSelectedSubtask(subtask);
    setShowModal(true);
  };

  return (
    <div className="subtasks-list">
      <div className="is-flex is-justify-content-space-between is-align-items-center mb-3">
        <h4 className="title is-6 mb-0">Subtasks</h4>
        <Button
          type="primary"
          size="small"
          className="is-flex is-align-items-center"
          onClick={onAddSubtask}
          icon={<FaPlusSquare />}
        >
          Add
        </Button>
      </div>

      {item.subitems?.map((subtask) => (
        <SubTaskItem
          key={subtask.id}
          subtask={subtask}
          onEditSubtask={onEditSubtask}
        />
      ))}

      {(!item.subitems || item.subitems.length === 0) && (
        <div className="has-text-centered has-text-grey py-4">
          No subtasks yet. Click "Add Subtask" to create one.
        </div>
      )}

      {showModal && (
        <ItemModal
          defaultOpen
          columnId={item.columnId}
          parentItem={item}
          item={selectedSubtask}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default observer(SubtasksList);

const SubTaskItem = ({
  subtask,
  onEditSubtask,
}: {
  subtask: BoardItemCard;
  onEditSubtask: (subtask: BoardItemCard) => void;
}) => {
  const { t } = useTranslation();
  const { users } = useStore("users");

  const assignedUser = useMemo(
    () => users?.find((f) => f.id === subtask.assigneeId),
    [subtask.assigneeId, users]
  );

  return (
    <div
      key={subtask.id}
      className="subtask-item box p-3 mb-2 is-clickable hover-highlight"
      onClick={() => onEditSubtask(subtask)}
    >
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <div className="is-flex is-align-items-center">
          {subtask.itemId && (
            <span className="has-text-weight-semibold subtitle is-6 mb-0 mr-2">
              {subtask.itemId}
            </span>
          )}
          <p className="has-text-weight-medium">{subtask.name}</p>
        </div>
        <div
          className="is-flex is-align-items-center"
          style={{
            gap: 8,
          }}
        >
          <span
            className="has-text-weight-semibold has-text-white tag"
            style={{
              background: typeTypeColorMap[(subtask.type as never) ?? "ticket"],
            }}
          >
            {t(subtask.type ?? locale.ticket)}
          </span>

          {subtask.priority && (
            <span
              className="ml-2"
              style={{
                color: ticketPriorityColorMap[subtask.priority],
                fontWeight: 500,
                fontSize: 12,
              }}
            >
              {t(subtask.priority)}
            </span>
          )}

          {assignedUser && (
            <div className="image is-32x32">
              <FallbackImage
                title={t(locale.assignee) + " " + String(assignedUser?.name)}
                className="is-rounded"
                src={assignedUser?.avatarUrl}
                fallback="/assets/avatar.svg"
                alt={assignedUser?.name || subtask.assigneeId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
