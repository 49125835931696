import { makeAutoObservable } from "mobx";
import {
  EdnaBotId,
  EdnaChat,
  EdnaChatInfo,
  EdnaChatMention,
  EdnaChatType,
  EdnaMessage,
  EdnaMessageParser,
  ednaBotUser,
} from "../models/EdnaChat";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import auth from "./auth";
import firebaseApp from "firebase/compat/app";
import { logError } from "../services/logging";
import locale from "../constants/locale";
import i18n from "../services/localization";
import { callAITeam, callEdnaBot } from "../services/chathub";
import users from "./users";
import bots from "./bots";
import aITeams from "./aITeams";
import chats from "./chats";
import ednaThreadChats from "./ednaThreadChats";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";
import i18next from "i18next";
import hubs from "./hubs";
import workflows from "./workflows";

type EdnaChatThreads = EdnaChat & {
  messages?: EdnaMessage[];
};

class EdnaChatsStore {
  private db: firebase.firestore.Firestore;
  private notificationAudio = new Audio("/assets/notification.mp3");
  private lastChatMessage = "";
  selectedChat?: EdnaChat;
  chatInfo?: EdnaChatInfo;
  messages: EdnaMessage[] = [];
  chatMentions: EdnaChatMention[] = [];
  chatThreads: EdnaChatThreads[] = [];
  findingChat = false;
  private loadingMessage = false;
  newMessageAlerts: string[] = [];
  workspaceId?: string;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  firstOrCreateChat = async (
    type: EdnaChatType,
    room: string,
    extras?: Record<string, any>
  ) => {
    this.clearChatAndMessages();

    if (!this.workspaceId || !auth.user || this.findingChat) {
      return;
    }

    this.findingChat = true;
    let chat: EdnaChat;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .where("type", "==", type)
      .where("room", "==", room)
      .limit(1)
      .get();

    if (snapshot.empty) {
      chat = {
        type,
        room,
        extras,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await this.db
        .collection("workspaces")
        .doc(this.workspaceId)
        .collection("ednaChats")
        .add(chat);

      const snapshot = await docRef.get();
      chat = snapshot.data() as EdnaChat;
      chat.id = docRef.id;
    } else {
      chat = snapshot.docs[0].data() as EdnaChat;
      chat.id = snapshot.docs[0].id;
      await this.updateLastMessageSeenByUserIds(chat);
    }

    if (chat.type === EdnaChatType.HUB) {
      await hubs.selectHub(chat.room.replace("hub-", ""));
    }

    this.setChat(chat);
    this.findingChat = false;
  };

  sendMessage = async (message: EdnaMessageParser) => {
    if (!this.workspaceId || !auth.user || !this.selectedChat?.id) {
      return;
    }

    const messageObj: EdnaMessage = {
      userId: auth.user.id,
      message,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    const docRef = await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .collection("messages")
      .add(messageObj);

    const snapshot = await docRef.get();
    const newMessage = snapshot.data() as EdnaMessage;
    newMessage.id = docRef.id;

    this.insertNewMessage(newMessage);

    await this.updateLastMessage(auth.user.id);
    await this.mentionedUsers(this.selectedChat, newMessage);

    if (this.selectedChat.type === EdnaChatType.BOT) {
      this.generateEdnaBotMessage(newMessage);
    }

    if (this.selectedChat.type === EdnaChatType.AI_TEAM) {
      this.generateAITeamMessage(newMessage);
    }
  };

  sendForwardMessage = async (
    message: EdnaMessageParser,
    roomId: string,
    chatType: EdnaChatType
  ) => {
    if (!workspaces.selectedWorkspace || !auth.user || !roomId) {
      return;
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .where("type", "==", chatType)
      .where("room", "==", roomId)
      .limit(1)
      .get();

    if (snapshot.empty) {
      return;
    }

    const chatId = snapshot.docs[0].id;

    const messageObj: EdnaMessage = {
      userId: auth.user.id,
      message,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(chatId)
      .collection("messages")
      .add(messageObj);
  };

  updateLastMessage = async (userId: string) => {
    if (!this.workspaceId || !auth.user || !this.selectedChat?.id) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .set(
        {
          lastMessageUserId: userId,
          lastMessageSeenByUserIds: [],
        },
        { merge: true }
      );
  };

  generateEdnaBotMessage = async (message: EdnaMessage) => {
    if (!workspaces.selectedWorkspace || !this.selectedChat?.id) return;

    const chatId = this.selectedChat.id;

    let liveChatFilters = auth.getPermissions()?.liveChatFilters as any;

    const reply = await callEdnaBot(
      workspaces.selectedWorkspace.id,
      message?.message?.text || "",
      await this.getEmbeddingTypes(),
      {
        userId: auth.user?.id,
        liveChatFilters,
      },
      i18next.language
    );

    const messageObj: EdnaMessage = {
      userId: EdnaBotId,
      message: { type: "text", text: reply },
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    const docRef = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(chatId)
      .collection("messages")
      .add(messageObj);

    if (chatId !== this.selectedChat?.id) {
      return;
    }

    const snapshot = await docRef.get();
    const newMessage = snapshot.data() as EdnaMessage;
    newMessage.id = docRef.id;

    this.insertNewMessage(newMessage);

    this.lastChatMessage = newMessage.id;

    this.playNotification();
    await this.updateLastMessage(EdnaBotId);
  };

  generateAITeamMessage = async (message: EdnaMessage) => {
    if (!workspaces.selectedWorkspace || !this.selectedChat?.id) return;

    let messages: any[] = [];

    const aITeamCSMId = this.selectedChat?.aITeamCommunicationStartedMessageId;

    if (aITeamCSMId) {
      const index = this.messages
        .slice()
        .reverse()
        .findIndex((f) => f.id === aITeamCSMId);

      if (index > -1) {
        messages = this.messages
          .slice()
          .reverse()
          .slice(index, this.messages.length - 1)
          .map((f) => ({
            userId: f.userId,
            message: f.message.text,
          }));
      }
    }

    const chatId = this.selectedChat.id;
    const aITeam = aITeams.aITeams.find(
      (f) => f.id === this.selectedChat?.room.replace("ai-team-", "")
    );

    const response = await callAITeam(
      workspaces.selectedWorkspace.id,
      this.selectedChat.id,
      this.selectedChat.room.replace("ai-team-", ""),
      aITeam?.name || "",
      message?.message?.text || "",
      messages,
      auth.user?.id || ""
    );

    const messageObj: EdnaMessage = {
      userId: response.userId,
      message: { type: "text", text: response.message },
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    const docRef = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(chatId)
      .collection("messages")
      .add(messageObj);

    if (chatId !== this.selectedChat?.id) {
      return;
    }

    const snapshot = await docRef.get();
    const newMessage = snapshot.data() as EdnaMessage;
    newMessage.id = docRef.id;

    this.insertNewMessage(newMessage);

    this.lastChatMessage = newMessage.id;

    this.playNotification();
    await this.updateLastMessage(EdnaBotId);
    await this.setAITeamComunicationId(message);
  };

  setAITeamComunicationId = async (message: EdnaMessage) => {
    if (!workspaces.selectedWorkspace || !auth.user || !this.selectedChat?.id)
      return;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .get();

    const lastestChat = snapshot.data() as EdnaChat;

    if (!lastestChat.aITeamCommunicationStartedMessageId) {
      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("ednaChats")
        .doc(this.selectedChat.id)
        .set(
          {
            aITeamCommunicationStartedMessageId: message.id,
          },
          { merge: true }
        );

      this.setChat({
        ...this.selectedChat,
        aITeamCommunicationStartedMessageId: message.id,
      });
    }
  };

  loadMessages = async (pageSize = 100) => {
    if (!this.workspaceId || !this.selectedChat?.id || this.loadingMessage) {
      return;
    }

    this.loadingMessage = true;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .collection("messages")
      .orderBy("createdAt", "desc")
      .get();

    const messages = snapshot.docs.map((f) => {
      const ref = f.data();
      ref.id = f.id;
      return ref;
    }) as EdnaMessage[];

    this.setMessages(messages);

    const lastMessage =
      messages.slice().find((f) => f.userId !== auth.user?.id)?.id || "";

    if (this.lastChatMessage && lastMessage !== this.lastChatMessage) {
      this.playNotification();
    }

    await this.updateLastMessageSeenByUserIds(this.selectedChat);
    this.updateChat();

    this.lastChatMessage = lastMessage;

    this.loadingMessage = false;
  };

  loadChatMentions = async () => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChatMentions")
      .where("mentionedUserId", "==", auth.user?.id)
      .orderBy("createdAt", "desc")
      .get();

    const mentions = (await Promise.all(
      snapshot.docs.map(async (f) => {
        const ref = f.data();
        const chatSnapshot = await this.db
          .collection("workspaces")
          .doc(String(workspaces.selectedWorkspace?.id || ""))
          .collection("ednaChats")
          .doc(ref.chatId)
          .get();

        ref.chat = chatSnapshot.data() as EdnaChat;

        const messageSnapshot = await this.db
          .collection("workspaces")
          .doc(String(workspaces.selectedWorkspace?.id || ""))
          .collection("ednaChats")
          .doc(ref.chatId)
          .collection("messages")
          .doc(ref.messageId)
          .get();

        ref.message = messageSnapshot.data() as EdnaMessage;

        return ref;
      })
    )) as EdnaChatMention[];

    this.setChatMentions(mentions.filter((f) => f.chat && f.message));
  };

  loadChatThreads = async () => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    // for users + livecards
    const authUserId = auth.user.id;

    const usersRef = (users.users || []).filter((f) => f.id !== authUserId);

    // active livecards
    const liveCardsRef = workspaces.activeLiveChatCards ?? [];

    const rooms = [] as string[];

    //add users
    for (let user of usersRef) {
      const room = [user.id, auth.user?.id || ""]
        .sort((a, b) => a.localeCompare(b))
        .join("|");

      rooms.push(room);
    }

    //add livcards
    for (let liveCard of liveCardsRef) {
      const room = "livecard-" + liveCard.id;
      rooms.push(room);
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .where("type", "==", EdnaChatType.THREAD)
      .where("room", "in", rooms)
      .where("hasThreadInteractions", "==", true)
      .orderBy("updatedAt", "desc")
      .get();

    const chats = (await Promise.all(
      snapshot.docs.map(async (f) => {
        const ref = f.data();
        ref.id = f.id;
        const messageSnapshot = await this.db
          .collection("workspaces")
          .doc(String(workspaces.selectedWorkspace?.id || ""))
          .collection("ednaChats")
          .doc(ref.id)
          .collection("messages")
          .orderBy("createdAt", "desc")
          .get();

        ref.messages = messageSnapshot.docs
          .map((f) => f.data())
          .reverse() as EdnaMessage[];
        return ref;
      })
    )) as EdnaChat[];

    this.setChatThreads(chats);
  };

  clearChatAndMessages = () => {
    this.selectedChat = undefined;
    this.messages = [];
    this.lastChatMessage = "";
    ednaThreadChats.clearChatAndMessages();
  };

  loadNewMessageAlert = async () => {
    if (!workspaces.selectedWorkspace?.id || !auth?.user?.id) return;
    // for users + livecards
    const authUserId = auth.user.id;

    const usersRef = (users.users || [])
      .concat([ednaBotUser])
      .filter((f) => f.id !== authUserId);

    const liveCardsRef = bots.activeLiveChatCards || [];

    const rooms = {} as Record<string, string>;
    const dMRooms = [] as string[];

    //add users
    for (let user of usersRef) {
      const room = [user.id, auth.user?.id || ""]
        .sort((a, b) => a.localeCompare(b))
        .join("|");

      rooms[room] = user.id;
      dMRooms.push(room);
    }

    //add livcards
    for (let liveCard of liveCardsRef) {
      const room = "livecard-" + liveCard.id;
      rooms[room] = liveCard.id.toString();
    }

    const chunkSize = 30;
    const roomsRef = Object.keys(rooms);
    const roomChunks = [];

    for (let i = 0; i < roomsRef.length; i += chunkSize) {
      roomChunks.push(roomsRef.slice(i, i + chunkSize));
    }

    // Create and execute queries for each chunk
    const queryPromises = roomChunks.map(async (roomChunk) => {
      const snapshot = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace?.id))
        .collection("ednaChats")
        .where("room", "in", roomChunk)
        .where("lastMessageUserId", "!=", authUserId)
        .get();

      return snapshot.docs.map((f) => f.data() as EdnaChat);
    });

    const hubsPromises =
      hubs.hubs?.map(async (hub) => {
        const room = `hub-${hub.id}`;
        rooms[room] = String(hub.id);
        const snapshot = await this.db
          .collection("workspaces")
          .doc(hub.workspaceId)
          .collection("ednaChats")
          .where("room", "==", `hub-${hub.id}`)
          .where("lastMessageUserId", "!=", authUserId)
          .get();

        return snapshot.docs.map((f) => f.data() as EdnaChat);
      }) ?? [];

    const results = await Promise.all([...queryPromises, ...hubsPromises]);
    const chatsRoom = results
      .flat()
      .filter((f) => f.lastMessageSeenByUserIds?.indexOf(authUserId) === -1)
      .map((f) => f.room);

    const chatsFound = chatsRoom.map((f) => rooms[f]) as string[];

    this.setNewMessageAlerts(chatsFound);
  };

  setChatInfo = (chatInfo: EdnaChatInfo) => {
    this.chatInfo = chatInfo;
  };

  private getEmbeddingTypes = async () => {
    if (!workspaces?.selectedWorkspace?.ednaBotSettings?.embeddingTypes) {
      return [];
    }

    return workspaces.selectedWorkspace.ednaBotSettings?.embeddingTypes || [];
  };

  private playNotification = async () => {
    this.notificationAudio.play().catch(function (error) {
      console.log("Chrome cannot play sound without user interaction first");
    });

    if (document.visibilityState === "visible") {
      return;
    }

    try {
      await Notification.requestPermission();
      new Notification("EDNA Platform", {
        body: i18n.t(locale.newMessageReceived),
      });
    } catch (error) {
      logError(error);
    }
  };

  private async updateChat() {
    if (!this.workspaceId || !this.selectedChat) return;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(this.selectedChat.id)
      .get();

    const chat = snapshot.data() as EdnaChat;

    this.setChat({
      ...this.selectedChat,
      ...chat,
    });
  }

  //TODO: handle hub case
  public async mentionedUsers(chat: EdnaChat, message: EdnaMessage) {
    if (message.message.type !== "text") return;

    const userIds =
      message?.message?.text
        ?.match(/(@\[.*?\]\([^)]+\))/g)
        ?.map((f) => f.replace(/(@\[.*?\]\(|\))/g, "")) || [];

    const uniqueUserIds = Array.from(new Set(userIds));

    for (const userId of uniqueUserIds) {
      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace?.id))
        .collection("ednaChatMentions")
        .add({
          room: chat.room,
          chatId: chat.id,
          messageId: message.id,
          mentionedUserId: userId,
          createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        });

      await workflows.triggerWorkflowEvent("automation_user", {
        dmMentionedUserId: userId,
        isThread: chat.type === EdnaChatType.THREAD,
        ednaChatRoom: chat.room,
        ednaChatId: chat.id,
        ednaMessageId: message.id,
      });

      await notifications.createNotification(userId, NotificationType.MENTION, {
        chatName:
          chat.type === EdnaChatType.SINGLE
            ? "theirChat"
            : this.chatInfo?.name || "",
        chat,
        message,
      });
    }

    //TODO fix for thread
    if (
      uniqueUserIds.length > 0 &&
      chat.type === EdnaChatType.CHATHUB_CHAT &&
      chats.selectedChat?.id
    ) {
      const oldChatMentionedUsers = JSON.parse(
        chats.selectedChat?.data?.e_cus_mentioned_users || "[]"
      ) as string[];

      chats.saveData({
        e_cus_mentioned_users: JSON.stringify(
          Array.from(new Set([...uniqueUserIds, ...oldChatMentionedUsers]))
        ),
      });
    }
  }

  private insertNewMessage(message: EdnaMessage) {
    const index = this.messages.findIndex((f) => f.id === message.id);
    if (index === -1) {
      this.setMessages([message, ...this.messages]);
    }
  }

  private setChat(chat: EdnaChat) {
    this.selectedChat = chat;
  }

  private setMessages(messages: EdnaMessage[]) {
    this.messages = messages;
  }

  private setChatMentions(mentions: EdnaChatMention[]) {
    this.chatMentions = mentions;
  }

  private setChatThreads(chatThreads: EdnaChatThreads[]) {
    this.chatThreads = chatThreads;
  }

  private setNewMessageAlerts(chatsFound: string[]) {
    this.newMessageAlerts = chatsFound;
  }

  private async updateLastMessageSeenByUserIds(chat: EdnaChat) {
    if (!this.workspaceId || !auth.user || !chat?.id) return;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(chat.id)
      .get();

    const lastestChat = snapshot.data() as EdnaChat;

    const lastMessageSeenByUserIds = Array.from(
      new Set(
        [...(lastestChat.lastMessageSeenByUserIds || []), auth.user.id].filter(
          (f) => f
        )
      )
    );

    await this.db
      .collection("workspaces")
      .doc(this.workspaceId)
      .collection("ednaChats")
      .doc(chat.id)
      .set(
        {
          lastMessageSeenByUserIds,
        },
        { merge: true }
      );
  }

  deleteChat = async (id: string) => {
    if (!workspaces.selectedWorkspace || !auth.user) {
      return;
    }

    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("ednaChats")
      .doc(id)
      .delete();
  };

  setWorkspaceId = (workspaceId: string) => {
    this.workspaceId = workspaceId;
  };
}

export default new EdnaChatsStore();
