import { Menu, SubMenu } from "../components";
import locale from "../constants/locale";
import routes from "../constants/routes";
import { LiveChatCard, User, UserType } from "../models";
import { Workspace, WorkspaceFunction } from "../models/Workspace";

export const buildMenus = (
  workspaceId: string,
  functionId: string,
  location: any,
  liveChatCards?: LiveChatCard[]
) => {
  const route = (route: string) => {
    return route
      .replace(":workspaceId", workspaceId)
      .replace(":functionId", functionId);
  };

  let menus: Menu[] = [
    {
      text: locale.chats,
      children: [
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=my-queue",
          text: locale.myQueue,
          module: "CHATS",
          icon: "/assets/myQueue-tab.svg",
          active:
            (location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) &&
              !location.search) ||
            location.search === "?tab=my-queue",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=chatrooms",
          text: locale.chatrooms,
          module: "CHATS",
          icon: "/assets/chatrooms-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=chatrooms",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=snoozed",
          text: locale.snoozed,
          module: "CHATS",
          icon: "/assets/snoozed-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=snoozed",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=requested",
          text: locale.requested,
          module: "CHATS",
          icon: "/assets/requested-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=requested",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=connected",
          text: locale.connected,
          module: "CHATS",
          icon: "/assets/connected-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=connected",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=all",
          text: locale.all,
          module: "CHATS",
          icon: "/assets/all-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=all",
        },
        {
          to: route(routes.chats).replace("/:chatId?", "") + "?tab=blocked",
          text: locale.blocked,
          module: "CHATS",
          icon: "/assets/blocked-tab.svg",
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search === "?tab=blocked",
        },
      ],
    },
    {
      text: locale.teams,
      children:
        liveChatCards?.map((liveCard) => ({
          text: liveCard.name,
          module: "CHATS",
          icon: "/assets/teams.svg",
          isEllipsis: true,
          active:
            location.pathname.startsWith(
              route(routes.chats).replace("/:chatId?", "")
            ) && location.search.includes("group"),
          children: [
            {
              to: route(routes.groupChats).replace(":group", `${liveCard.id}`),
              text: locale.groupChats,
              active: location.pathname.startsWith(
                route(routes.groupChats).replace(":group", `${liveCard.id}`)
              ),
            },
            {
              to: route(routes.tickets).replace(":group", `${liveCard.id}`),
              text: locale.work,
              active: location.pathname.startsWith(
                route(routes.tickets).replace(":group", `${liveCard.id}`)
              ),
            },
            {
              to: route(routes.groupCalendar).replace(
                ":group",
                `${liveCard.id}`
              ),
              text: locale.calendar,
              active: location.pathname.startsWith(
                route(routes.groupCalendar).replace(":group", `${liveCard.id}`)
              ),
            },
            {
              to:
                route(routes.chats).replace("/:chatId?", "") +
                "?tab=requested&group=" +
                liveCard.id,
              text: locale.requested,
              active:
                location.pathname.startsWith(
                  route(routes.chats).replace("/:chatId?", "")
                ) && location.search === "?tab=requested&group=" + liveCard.id,
            },
            {
              to:
                route(routes.chats).replace("/:chatId?", "") +
                "?tab=connected&group=" +
                liveCard.id,
              text: locale.connected,
              active:
                location.pathname.startsWith(
                  route(routes.chats).replace("/:chatId?", "")
                ) && location.search === "?tab=connected&group=" + liveCard.id,
            },
          ],
        })) ?? [],
    },
    {
      text: locale.automation,
      children: [
        {
          to: route(routes.workflows).replace("/:categoryId?", ""),
          text: locale.workflows,
          module: "WORKFLOWS",
          icon: "/assets/workflow.svg",
          active: location.pathname.startsWith(
            route(routes.workflows).replace("/:categoryId?", "")
          ),
          roles: [UserType.Administrator, UserType.Root],
        },
      ],
    },
    {
      text: locale.support,
      children: [
        {
          to: route(routes.cannedResponses),
          text: locale.cannedResponses,
          module: "QUICK_REPLIES",
          icon: "/assets/template_edna.svg",
          active: location.pathname.startsWith(route(routes.cannedResponses)),
        },
        {
          to: route(routes.contacts),
          text: locale.contacts,
          module: "CONTACTS",
          icon: "/assets/contacts.svg",
          active: location.pathname.startsWith(route(routes.contacts)),
        },
        {
          to: route(routes.analytics),
          text: locale.analytics,
          module: "ANALYTICS",
          icon: "/assets/analytics.svg",
          active: location.pathname.startsWith(route(routes.analytics)),
        },
      ],
    },
    {
      text: locale.engage,
      children: [
        {
          to: route(routes.liveStream).replace("/:chatId?", ""),
          text: locale.liveStream,
          module: "LIVE_STREAM",
          icon: "/assets/broadcast.svg",
          active: location.pathname.startsWith(
            route(routes.liveStream).replace("/:chatId?", "")
          ),
        },
        {
          to: route(routes.campaigns).replace("/:campaignId?", ""),
          text: locale.campaigns,
          module: "CAMPAIGNS",
          icon: "/assets/campaigns.svg",
          active: location.pathname.startsWith(
            route(routes.campaigns).replace("/:campaignId?", "")
          ),
        },
      ],
    },
    {
      text: locale.convert,
      children: [
        {
          to: route(routes.board).replace("/:boardId?", ""),
          text: locale.pipeline,
          module: "BOARDS",
          icon: "/assets/pipeline.svg",
          active: location.pathname.startsWith(
            route(routes.board).replace("/:boardId?", "")
          ),
        },
        {
          to: route(routes.catalog),
          text: locale.catalogs,
          module: "CATALOGS",
          icon: "/assets/catalog-icon.svg",
          active: location.pathname.startsWith(route(routes.catalog)),
          roles: [UserType.Administrator, UserType.Root],
        },
        {
          text: locale.commerce,
          module: "COMMERCE",
          icon: "/assets/commerce-icon.svg",
          active: false,
          roles: [UserType.Administrator, UserType.Root],
          children: [
            {
              to: route(routes.commerceBranches),
              text: locale.commerceBranches,
              active: location.pathname.startsWith(
                route(routes.commerceBranches)
              ),
            },
            {
              to: route(routes.commerceProducts),
              text: locale.commerceProducts,
              active: location.pathname.startsWith(
                route(routes.commerceProducts)
              ),
            },
            {
              to: route(routes.commerceProfile),
              text: locale.commerceProfile,
              active: location.pathname.startsWith(
                route(routes.commerceProfile)
              ),
            },
          ],
        },
      ],
    },
    {
      text: locale.preferences,
      children: [
        {
          to: route(routes.setup),
          text: locale.gettingStarted,
          module: "SETUP",
          icon: "/assets/setup-edna.svg",
          active: location.pathname.startsWith(route(routes.setup)),
          roles: [UserType.Administrator, UserType.Root],
        },
        // {
        //   to: routes.errorLogs.replace(":botId", String(botId)),
        //   text: locale.errorLogs,
        //   module: "ERROR_LOGS",
        //   icon: "/assets/error-logs.svg",
        //   active: location.pathname.startsWith(
        //     routes.errorLogs.replace(":botId", String(botId))
        //   ),
        //   roles: [UserType.Administrator, UserType.Root],
        // },
        {
          to: route(routes.settings),
          text: locale.settings,
          module: "SETTINGS",
          icon: "/assets/settings_edna.svg",
          active: location.pathname.startsWith(route(routes.settings)),
          roles: [UserType.Administrator, UserType.Root],
        },
      ],
    },
  ];

  return menus;
};

export const checkSubMenuPermission = (
  selectedWorkspace: Workspace,
  selectedFunction: WorkspaceFunction,
  user: User,
  subMenu: SubMenu
) => {
  const allowedModules =
    selectedFunction?.features
      ?.map((feature) => feature.modules)
      ?.flatMap((m) => m) ?? [];

  if (
    !["SETTINGS", "SETUP"].includes(subMenu.module) &&
    !allowedModules.includes(subMenu.module)
  ) {
    return false;
  }

  // if (user.type === UserType.Collaborator && subMenu.module === "ANALYTICS")
  //   return false;

  if (subMenu.roles?.length && !subMenu.roles?.includes(user?.type!)) {
    return false;
  }

  if (
    [UserType.Supervisor, UserType.Collaborator].includes(user?.type) &&
    user?.permissions?.perWorkspace?.[selectedWorkspace.id]?.perFunction?.[
      selectedFunction.slug
    ]?.restrictedModules?.includes(subMenu.module)
  ) {
    return false;
  }

  return true;
};
