import { EdnaMessageParser } from "../../../../models/EdnaChat";
import { RenderFile } from "./RenderFIle";
import { RenderText } from "./RenderText";
import { RenderTicket } from "./RenderTicket";

export const MessageRenders = ({ message }: { message: EdnaMessageParser }) => {
  const types = {
    text: RenderText,
    file: RenderFile,
    ticket: RenderTicket,
  };

  const Component = types[message.type];

  return <Component message={message} />;
};
