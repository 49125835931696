import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import firebaseApp from "firebase/compat/app";
import { OKDocument, OKDocumentSource, OKFolder } from "../models/OrgKnowledge";
import { generateEmbedding, removeEmbedding } from "../services/chathub";
import { EmbeddingStatus } from "../models/HelpCenter";
import { v4 as uuid } from "uuid";

class OrganizationKnowledgeStore {
  private db: firebase.firestore.Firestore;
  folders: OKFolder[] = [];
  selectedFolder?: OKFolder;
  documents: OKDocument[] = [];

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  updateOrCreateFolder = async (folder: OKFolder) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    if (folder?.id) {
      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeFolders")
        .doc(folder.id)
        .set(
          {
            name: folder.name,
            updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );

      const index = this.folders.findIndex((f) => f.id === folder.id);

      if (index > -1) {
        this.folders[index] = {
          ...this.folders[index],
          name: folder.name,
        };
      }

      if (this.selectedFolder?.id === folder.id) {
        this.selectFolder(folder);
      }
    } else {
      const folderObj: OKFolder = {
        name: folder.name,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeFolders")
        .add(folderObj);

      const newFolder = (await docRef.get()).data() as OKFolder;
      newFolder.id = docRef.id;
      this.folders.unshift(newFolder);
    }
  };

  deleteFolder = async (folder: OKFolder) => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    if (folder?.id) {
      await this.deleteAllDocumentsOfAFolder(folder.id);

      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeFolders")
        .doc(folder.id)
        .delete();

      const index = this.folders.findIndex((f) => f.id === folder.id);

      if (index > -1) {
        this.folders.splice(index, 1);
      }
    }
  };

  loadFolders = async () => {
    if (!workspaces.selectedWorkspace) {
      return;
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("orgKnowledgeFolders")
      .orderBy("createdAt", "desc")
      .get();

    const folders = snapshot.docs.map((doc) => {
      const obj = doc.data() as OKFolder;
      obj.id = doc.id;
      return obj;
    }) as OKFolder[];

    this.folders = folders;
  };

  selectFolder = (folder: OKFolder) => {
    this.selectedFolder = folder;
  };

  updateOrCreateDocument = async (document: OKDocument) => {
    if (!workspaces.selectedWorkspace || !this.selectedFolder) {
      return;
    }

    if (document?.id) {
      if (
        document.content instanceof File &&
        document.source === OKDocumentSource.UPLOAD
      ) {
        const ref = firebase.storage().ref(`files`);
        const fileName = document.content.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
        const fileRef = ref.child(`${uuid()}.${fileExtension}`);
        await fileRef.put(document.content, {
          contentType: (document.content as File).type,
        });
        document.content = await fileRef.getDownloadURL();
      }

      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeDocuments")
        .doc(document.id)
        .set(
          {
            folderId: this.selectedFolder.id,
            name: document.name,
            content: document.content,
            source: document.source,
            embeddingStatus: EmbeddingStatus.PENDING,
            updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );

      const index = this.documents.findIndex((f) => f.id === document.id);

      const updatedDocument = {
        ...this.documents[index],
        ...document,
        embeddingStatus: EmbeddingStatus.PENDING,
      };

      if (index > -1) {
        this.documents[index] = updatedDocument;
      }

      await generateEmbedding(workspaces.selectedWorkspace.id, {
        collectionId: document.id,
        collectionName: `org-knowledge-${this.selectedFolder.id}`,
        meta: {
          source: document.source,
          content: document.content,
          name: document.name,
        },
        text: `Name: ${updatedDocument.name}
                 Content: ${updatedDocument.content}`,
      });
    } else {
      if (document.source === OKDocumentSource.UPLOAD) {
        const ref = firebase.storage().ref(`files`);
        const fileName = document.content.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
        const fileRef = ref.child(`${uuid()}.${fileExtension}`);
        await fileRef.put(document.content, {
          contentType: (document.content as File).type,
        });
        document.content = await fileRef.getDownloadURL();
      }

      const documentObj: OKDocument = {
        ...document,
        folderId: this.selectedFolder?.id || "",
        embeddingStatus: EmbeddingStatus.PENDING,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeDocuments")
        .add(documentObj);

      await this.updateFolderCount(1);

      const newDocument = (await docRef.get()).data() as OKDocument;
      newDocument.id = docRef.id;
      this.documents.unshift(newDocument);

      await generateEmbedding(workspaces.selectedWorkspace.id, {
        collectionId: newDocument.id,
        collectionName: `org-knowledge-${this.selectedFolder.id}`,
        meta: {
          source: newDocument.source,
          content: newDocument.content,
          name: newDocument.name,
        },
        text: `Name: ${newDocument.name}
                 Content: ${newDocument.content}`,
      });
    }
  };

  loadDocuments = async () => {
    if (!workspaces.selectedWorkspace || !this.selectedFolder) {
      return;
    }

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("orgKnowledgeDocuments")
      .where("folderId", "==", this.selectedFolder.id)
      .orderBy("createdAt", "desc")
      .get();

    const documents = snapshot.docs.map((doc) => {
      const obj = doc.data() as OKDocument;
      obj.id = doc.id;
      return obj;
    }) as OKDocument[];

    this.documents = documents;
  };

  deleteDocument = async (
    document: OKDocument,
    dontUpdateFolderCount = false
  ) => {
    if (!workspaces.selectedWorkspace || !this.selectedFolder) {
      return;
    }
    if (document?.id) {
      await this.db
        .collection("workspaces")
        .doc(String(workspaces.selectedWorkspace.id))
        .collection("orgKnowledgeDocuments")
        .doc(document.id)
        .delete();

      await removeEmbedding(workspaces.selectedWorkspace.id, {
        collectionId: document.id,
        collectionName: `org-knowledge-${this.selectedFolder.id}`,
      });

      const index = this.documents.findIndex((f) => f.id === document.id);

      if (index > -1) {
        this.documents.splice(index, 1);
      }

      if (!dontUpdateFolderCount) {
        await this.updateFolderCount(-1);
      }
    }
  };

  updateFolderCount = async (amount: number) => {
    if (!workspaces.selectedWorkspace || !this.selectedFolder?.id) return;

    const documentsCount = (this.selectedFolder?.documentsCount || 0) + amount;

    await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("orgKnowledgeFolders")
      .doc(this.selectedFolder.id)
      .set(
        {
          documentsCount,
          updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

    const index = this.folders.findIndex(
      (f) => f.id === this.selectedFolder?.id
    );

    if (index > -1) {
      this.folders[index] = {
        ...this.folders[index],
        documentsCount,
      };
    }

    this.selectedFolder.documentsCount = documentsCount;
  };

  deleteAllDocumentsOfAFolder = async (folderId: string) => {
    if (!workspaces.selectedWorkspace) return;

    const snapshot = await this.db
      .collection("workspaces")
      .doc(String(workspaces.selectedWorkspace.id))
      .collection("orgKnowledgeDocuments")
      .where("folderId", "==", folderId)
      .get();

    for (let map of snapshot.docs) {
      const document = map.data() as OKDocument;
      document.id = map.id;
      await this.deleteDocument(document, true);
    }
  };

  clearChatAndMessages = () => {
    this.documents = [];
    this.folders = [];
    this.selectedFolder = undefined;
  };
}

export default new OrganizationKnowledgeStore();
