import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { toast } from "bulma-toast";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores";
import styles from "./MessageComposer.module.scss";
import locale from "../../../constants/locale";
import { EdnaChatType, EdnaMessageParser } from "../../../models/EdnaChat";
import AddAttachment from "./AddAttachment";
import PreviewFiles from "./PreviewFiles";
import { uploadFiles } from "../../../stores/utils";
import Mentions from "../../mentions/Mentions";

const MAX_ALLOWED_SIZE = 2 * 1024 * 1024; // 2MB

const MessageComposer = ({
  storeName = "ednaChats",
}: {
  storeName?: "ednaChats" | "ednaThreadChats";
}) => {
  const { t } = useTranslation();
  const auth = useStore("auth");
  const { users } = useStore("users");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [messageValue, setMessageValue] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const { selectedChat, sendMessage } = useStore(storeName);
  const [files, setFiles] = useState<File[]>([]);
  const [showRecorder, setShowRecorder] = useState(false);

  const buildTextPayload = async (text: string): Promise<EdnaMessageParser> => {
    return {
      type: "text",
      text,
    };
  };

  const buildFilePayload = async (file: File): Promise<EdnaMessageParser> => {
    try {
      const link = (await uploadFiles([file]))[0];

      return {
        type: "file",
        file: {
          name: file.name,
          link: link,
          type: file.type,
        },
      };
    } catch (e) {
      throw new Error("An error occured while uploading the file");
    }
  };

  const onSendMessage = useCallback(async () => {
    if (!messageValue?.length && !files.length) {
      return;
    }
    setSendingMessage(true);

    try {
      if (messageValue?.length) {
        sendMessage(await buildTextPayload(messageValue));
      }
      if (files?.length) {
        for (let file of files) {
          await sendMessage(await buildFilePayload(file));
        }
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    } finally {
      setSendingMessage(false);
      if (textAreaRef?.current) {
        setTimeout(() => {
          textAreaRef.current?.focus();
        }, 10);
      }
      setMessageValue("");
      setFiles([]);
    }
  }, [messageValue, files, sendMessage]);

  const onPaste = (event: any) => {
    if (
      ![
        EdnaChatType.SINGLE,
        EdnaChatType.CHANNEL,
        EdnaChatType.THREAD,
      ].includes(selectedChat?.type!)
    ) {
      return;
    }

    //@ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    const files = [];

    for (let i = 0; i < items.length; i++) {
      const file = items[i];
      if (
        [
          "audio/mpeg",
          "audio/mp3",
          "audio/ogg",
          "audio/amr",
          "image/jpeg",
          "image/png",
          "video/mp4",
          "application/pdf",
        ].includes(file.type)
      ) {
        files.push(file.getAsFile());
        if (hasSizeLimit(file.getAsFile())) {
          event.preventDefault();
          return;
        }
      }
    }

    if (files.length) {
      event.preventDefault();
      setFiles(files);
    }
  };

  const hasSizeLimit = useMemo(
    () => (blob: Blob) => {
      if (blob.size > MAX_ALLOWED_SIZE) {
        toast({
          message: t(locale.fileIsTooLarge, { size: `10 MB` }),
          position: "top-right",
          dismissible: true,
          type: "is-warning",
          pauseOnHover: true,
        });
        return true;
      }

      return false;
    },
    [t]
  );

  const listener = useCallback(
    (e: any) => {
      if (e.keyCode !== 13) {
        return false;
      }
      e.preventDefault();

      if (e.altKey || e.ctrlKey) {
        setMessageValue((p) => p + "\n");
      } else {
        onSendMessage();
      }
    },
    [onSendMessage]
  );

  const renderUserSuggestion = useCallback((suggestion: any) => {
    return <div>{suggestion.display}</div>;
  }, []);

  const displayTransformHandler = useCallback((_: any, display: any) => {
    return "@" + display;
  }, []);

  return (
    <div className="control px-4 py-2">
      <div
        className={clsx(styles.messageBox, "has-background-white has-border")}
      >
        <div className={styles.mentionContainer}>
          <Mentions
            refMention={textAreaRef}
            value={messageValue}
            onChange={(e: any) => {
              setMessageValue(e.target.value);
            }}
            trigger="@"
            data={users
              ?.filter((f) => f.id !== auth?.user?.id)
              ?.map((user: any) => ({
                id: user.id,
                display: user.name,
              }))}
            onKeyDown={listener}
            renderSuggestion={renderUserSuggestion}
            displayTransform={displayTransformHandler}
            markup={"@[__display__](__id__)"}
            placeholder={t(locale.writeMessage)}
            appendSpaceOnAdd={true}
            disabled={sendingMessage || selectedChat?.isChatDisabled}
            onPaste={onPaste}
          />
        </div>
        <PreviewFiles
          files={files}
          setFiles={(files) => setFiles(files)}
          showRecorder={showRecorder}
          setShowRecorder={setShowRecorder}
          onRemove={() => setFiles([])}
        />
        <div className="is-flex is-justify-content-space-between m-1">
          <div className="is-flex">
            {[
              EdnaChatType.SINGLE,
              EdnaChatType.CHANNEL,
              EdnaChatType.CHATHUB_CHAT,
              EdnaChatType.THREAD,
              EdnaChatType.HUB,
            ].includes(selectedChat?.type!) && (
              <AddAttachment
                storeName={storeName}
                isLoading={sendingMessage}
                setFiles={(files) => setFiles(files)}
                setShowRecorder={setShowRecorder}
              />
            )}
          </div>

          <button
            onClick={onSendMessage}
            className={clsx(
              "button is-ghost is-no-box-shadow has-text-primary"
            )}
            disabled={sendingMessage}
          >
            <span className="icon">
              <img src="/assets/message-send.svg" alt="" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(MessageComposer);
