import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import {
  Notification,
  MentionNotificationMeta,
  NotificationType,
  ThreadNotificationMeta,
  TicketMentionNotificationMeta,
  DealMentionNotificationMeta,
  parseNotificationText,
  HubInviteNotificationMeta,
} from "../models/Notification";
import auth from "./auth";
import { FirebaseSubscriber } from "./utils";
import { logError } from "../services/logging";
import { sendFCM } from "../services/chathub";
import bots from "./bots";
import locale from "../constants/locale";
import { t } from "i18next";
import users from "./users";

class NotificationsStore {
  private db: firebase.firestore.Firestore;
  private notificationSubscriber?: FirebaseSubscriber;
  notifications: Notification[] = [];
  private initialLoaded = false;

  constructor() {
    makeAutoObservable(this);
    this.db = firebase.firestore();
  }

  subscribedNotifications = async () => {
    if (!workspaces.selectedWorkspace || !auth.user) return;

    if (this.notificationSubscriber?.id === workspaces.selectedWorkspace.id) {
      return;
    }

    if (this.notificationSubscriber) {
      this.notificationSubscriber.unsubscribe();
      this.initialLoaded = false;
      this.setNotifications([]);
    }

    this.notificationSubscriber = {
      id: workspaces.selectedWorkspace.id,
      unsubscribe: this.db
        .collection("workspaces")
        .doc(workspaces.selectedWorkspace.id)
        .collection("notifications")
        .where("receiverId", "==", auth.user.id)
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (
            snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
          ) => {
            if (snap.empty) {
              this.setNotifications([]);
              return;
            }

            let temp = this.notifications.slice();

            snap.docChanges().forEach((change) => {
              const data = change.doc.data() as Notification;
              data.id = change.doc.id;
              switch (change.type) {
                case "added":
                  if (!this.initialLoaded) {
                    temp.push(data);
                  } else {
                    temp.unshift(data);
                  }
                  break;
                case "modified":
                  temp = temp.map((m) => (m.id === data.id ? data : m));
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            this.setNotifications(temp);
            if (!this.initialLoaded) {
              this.initialLoaded = true;
            }
          },
          logError
        ),
    };
  };

  createNotification = async (
    receiverId: string,
    type: NotificationType,
    meta:
      | TicketMentionNotificationMeta
      | MentionNotificationMeta
      | ThreadNotificationMeta
      | DealMentionNotificationMeta
      | HubInviteNotificationMeta
  ) => {
    if (!workspaces.selectedWorkspace || !auth.user) return;

    const notification = {
      receiverId,
      senderId: auth.user.id,
      type,
      meta,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("notifications")
      .add(notification);

    const senderUser = users.users?.find((f) => f.id === notification.senderId);
    if (senderUser) {
      await this.sendFCMNotification(
        [receiverId],
        t(locale.activity),
        `${senderUser.name} ${parseNotificationText(
          notification as Notification
        )}`
      );
    }
  };

  createNotificationToWorkspace = async (
    workspaceId: string,
    receiverId: string,
    type: NotificationType,
    meta:
      | TicketMentionNotificationMeta
      | MentionNotificationMeta
      | ThreadNotificationMeta
      | DealMentionNotificationMeta
      | HubInviteNotificationMeta
  ) => {
    const notification = {
      receiverId,
      senderId: auth.user?.id,
      senderName: auth.user?.name,
      type,
      meta,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("notifications")
      .add(notification);

    //TODO: FCM notification
  };

  markAsRead = async (notificationId: string) => {
    if (!workspaces.selectedWorkspace || !auth.user) return;

    await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("notifications")
      .doc(notificationId)
      .update({
        readAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };

  sendFCMNotification = async (
    userIds: string[],
    title: string,
    body: string
  ) => {
    if (!bots.selectedBot) {
      return;
    }
    await sendFCM(
      bots.selectedBot.id,
      bots.selectedBot.key,
      bots.selectedBot.password,
      userIds,
      title,
      body
    );
  };

  private setNotifications = (notifications: Notification[]) => {
    this.notifications = notifications;
  };
}

export default new NotificationsStore();
