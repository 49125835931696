import React, { useCallback, useState } from "react";
import { Modal } from "../../..";
import locale from "../../../../constants/locale";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Hub } from "../../../../models";
import { useStore } from "../../../../stores";
import { toast } from "bulma-toast";
import { useHistory } from "react-router";
import routes from "../../../../constants/routes";

const DeleteHubModal = ({ hub }: { hub: Hub }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [deletingHub, setDeletingHub] = useState(false);
  const { selectedWorkspace } = useStore("workspaces");
  const { deleteHub } = useStore("hubs");

  const onDeleteHub = useCallback(async () => {
    setDeletingHub(true);
    try {
      await deleteHub(hub.id!);
      toast({
        message: "Hub deleted successfully",
        type: "is-success",
        dismissible: true,
      });
      history.push(
        routes.teammates
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":userId?", "")
      );
    } catch (error) {
      toast({
        message: "Failed to delete hub",
        type: "is-danger",
        dismissible: true,
      });
    } finally {
      setDeletingHub(false);
    }
  }, [deleteHub, history, hub.id, selectedWorkspace?.id]);

  return (
    <>
      <div className="w-100 has-text-right">
        <button
          onClick={() => setOpen(true)}
          className="button p-0 is-no-box-shadow is-ghost has-text-danger"
        >
          {t(locale.delete)}
        </button>
      </div>
      <Modal
        isOpen={open}
        title={() => (
          <h2 className="title is-5">
            {t(locale.delete)} "{hub.name}"
          </h2>
        )}
        onClose={() => setOpen(false)}
        primaryText={t(locale.confirm)}
        primaryLoading={deletingHub}
        secondaryText={t(locale.cancel)}
        onPrimary={() => {
          setOpen(false);
          onDeleteHub();
        }}
        onSecondary={() => setOpen(false)}
      >
        <span
          className="is-6 subtitle"
          style={{
            fontSize: "12px",
          }}
        >
          {t(locale.hubDeleteConfirmation)}
        </span>
      </Modal>
    </>
  );
};

export default observer(DeleteHubModal);
