import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
//@ts-ignore
import detect from "detect.js";

import {
  User,
  UserPermissions,
  UserStatus,
  UserStatusLog,
  UserType,
} from "../models";
import { logError } from "../services/logging";

import firebaseApp from "firebase/compat/app";
import settings from "./settings";
import audit from "./audit";
import users from "./users";
import workspaces from "./workspaces";
import { multiFactor } from "firebase/auth";

class AuthStore {
  private auth: firebase.auth.Auth;
  private db: firebase.firestore.Firestore;

  initialized: boolean = false;
  user?: User;
  resolver?: firebase.auth.MultiFactorResolver;
  private bearerToken?: string;
  userHasAdminAccess = false;

  get firebaseUser() {
    return this.auth.currentUser;
  }

  constructor() {
    makeAutoObservable(this);

    this.auth = firebase.auth();
    this.db = firebase.firestore();

    this.loadAuthState();
  }

  private loadAuthState = () => {
    const unsubscribe = this.auth.onAuthStateChanged(
      async (user: firebase.User | null) => {
        this.setFirebaseUser(user).finally(() => {
          this.setInitialized(true);
          unsubscribe();
        });
      }
    );
  };

  setFirebaseUser = async (user: firebase.User | null) => {
    try {
      if (user && user.uid && !this.user) {
        this.bearerToken = await user.getIdToken();
        const { uid } = user;
        const data = await this.getUser(uid);
        this.setUser(data);

        if (!data) {
          this.logOut();
          return;
        }

        audit.logEvent("user_log_in", { id: uid, email: data.email });

        if (settings.language !== data.preferredLanguage) {
          settings.setLanguage(data.preferredLanguage);
        }
      }
    } catch (error) {
      logError(error);
    }
  };

  signIn = async (email: string, password: string) => {
    try {
      const credentials = await this.auth.signInWithEmailAndPassword(
        email,
        password
      );

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      const user = await this.getUser(credentials.user.uid);

      if (user?.isDisabled) {
        throw new Error("auth/user-disabled");
      }

      this.setUser(user);

      if (!this.user) {
        throw new Error("Something went wrong logging in");
      }

      audit.logEvent("user_log_in", {
        id: this.user.id,
        email: this.user.email,
      });

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }

      this.bearerToken = await credentials.user.getIdToken();
      await this.loginCompleted();
    } catch (error: any) {
      if (error.code !== "auth/multi-factor-auth-required") {
        logError(error);
      }
      throw error;
    }
  };

  signInWithGoogle = async (
    needsLinking: (cred: firebase.auth.AuthCredential, email: string) => void
  ) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credentials = await this.auth.signInWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      const user = await this.getUser(credentials.user.uid);

      if (user?.isDisabled) {
        throw new Error("auth/user-disabled");
      }

      this.setUser(user);

      if (!this.user) {
        await credentials.user.delete();
        throw new Error("auth/user-not-found");
      } else {
        this.setUser(
          await this.updateUser({
            ...this.user,
            info: {
              ...this.user.info,
              ...credentials.user.providerData[0],
            },
            updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
          })
        );
      }

      audit.logEvent("user_log_in", {
        id: this.user.id,
        email: this.user.email,
      });

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }

      this.bearerToken = await credentials.user.getIdToken();
      await this.loginCompleted();
    } catch (error: any) {
      // An error happened.
      if (error.code === "auth/account-exists-with-different-credential") {
        // Step 2.
        // User's email already exists.
        // The pending Facebook credential.
        var pendingCred = error.credential;
        // The provider account's email address.
        var email = error.email;
        // Get sign-in methods for this email.
        const methods = await this.auth.fetchSignInMethodsForEmail(email);
        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === "password") {
          // Asks the user their password.
          needsLinking(pendingCred, email);
          return;
        }
      } else if (error.code !== "auth/multi-factor-auth-required") {
        logError(error);
      }

      throw error;
    }
  };

  signInWithFacebook = async (
    needsLinking: (cred: firebase.auth.AuthCredential, email: string) => void
  ) => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      [
        "public_profile",
        "email",
        "pages_manage_metadata",
        "pages_messaging",
        "pages_messaging_subscriptions",
      ].forEach((s) => provider.addScope(s));

      const credentials = await this.auth.signInWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      const user = await this.getUser(credentials.user.uid);

      if (user?.isDisabled) {
        throw new Error("auth/user-disabled");
      }

      this.setUser(user);

      if (!this.user) {
        await credentials.user.delete();
        throw new Error("auth/user-not-found");
      } else {
        this.setUser(
          await this.updateUser({
            ...this.user,
            info: {
              ...this.user.info,
              facebookAccessToken: (credentials.credential?.toJSON() as any)
                .oauthAccessToken,
              id: credentials.user.providerData[0]?.uid,
              name: credentials.user.providerData[0]?.displayName,
              email: credentials.user.providerData[0]?.email || this.user.email,
              avatar: credentials.user.providerData[0]?.photoURL,
            },
            updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
          })
        );
      }

      audit.logEvent("user_log_in", {
        id: this.user.id,
        email: this.user.email,
      });

      if (settings.language !== this.user.preferredLanguage) {
        settings.setLanguage(this.user.preferredLanguage);
      }
    } catch (error: any) {
      // An error happened.
      if (error.code === "auth/account-exists-with-different-credential") {
        // Step 2.
        // User's email already exists.
        // The pending Facebook credential.
        var pendingCred = error.credential;
        // The provider account's email address.
        var email = error.email;
        // Get sign-in methods for this email.
        const methods = await this.auth.fetchSignInMethodsForEmail(email);
        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === "password") {
          // Asks the user their password.
          needsLinking(pendingCred, email);
          return;
        }
      }

      logError(error);
      throw error;
    }
  };

  linkFacebook = async () => {
    if (!this.auth.currentUser || !this.user) {
      throw new Error("auth/user-not-found");
    }

    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      [
        "public_profile",
        "email",
        "pages_manage_metadata",
        "pages_messaging",
        "pages_messaging_subscriptions",
      ].forEach((s) => provider.addScope(s));

      const credentials = await this.auth.currentUser.linkWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong logging in");
      }

      this.setUser(
        await this.updateUser({
          ...this.user,
          info: {
            ...this.user.info,
            facebookAccessToken: (credentials.credential?.toJSON() as any)
              .oauthAccessToken,
            id: credentials.user.providerData[0]?.uid,
            name: credentials.user.providerData[0]?.displayName,
            email: credentials.user.providerData[0]?.email || this.user.email,
            avatar: credentials.user.providerData[0]?.photoURL,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        })
      );

      audit.logEvent("user_log_in", {
        id: this.user.id,
        email: this.user.email,
      });

      await this.loginCompleted();

      return "ar";
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  signUp = async (
    email: string,
    password: string,
    name: string,
    affiliate?: string
  ) => {
    try {
      const credentials = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!credentials.user) {
        throw new Error("Something went wrong signing up");
      }

      const data = await this.updateUser({
        id: credentials.user.uid,
        email,
        type: UserType.Administrator,
        isDisabled: false,
        preferredLanguage: settings.language,
        name,
        affiliateId: affiliate,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      });

      this.setUser(data);

      // await credentials.user.sendEmailVerification();

      audit.logEvent("user_sign_up", {
        id: data.id,
        email: data.email,
      });

      if (settings.language !== data.preferredLanguage) {
        settings.setLanguage(data.preferredLanguage);
      }
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  signUpWithGoogle = async (
    needsLinking: (cred: firebase.auth.AuthCredential, email: string) => void,
    affiliate?: string
  ) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credentials = await this.auth.signInWithPopup(provider);

      if (!credentials.user) {
        throw new Error("Something went wrong signing up");
      }

      const data = await this.updateUser({
        id: credentials.user.uid,
        email: credentials.user.email || "",
        type: UserType.Administrator,
        isDisabled: false,
        preferredLanguage: settings.language,
        name: credentials.user.displayName || "",
        affiliateId: affiliate,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      });

      this.setUser(data);

      if (settings.language !== data.preferredLanguage) {
        settings.setLanguage(data.preferredLanguage);
      }

      audit.logEvent("user_sign_up", {
        id: data.id,
        email: data.email,
      });
    } catch (error: any) {
      // An error happened.
      if (error.code === "auth/account-exists-with-different-credential") {
        // Step 2.
        // User's email already exists.
        // The pending Facebook credential.
        var pendingCred = error.credential;
        // The provider account's email address.
        var email = error.email;
        // Get sign-in methods for this email.
        const methods = await this.auth.fetchSignInMethodsForEmail(email);
        // Step 3.
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        if (methods[0] === "password") {
          // Asks the user their password.
          needsLinking(pendingCred, email);
          return;
        }
      }

      logError(error);
      throw error;
    }
  };

  updateCurrentUser = async ({
    password,
    ...user
  }: User & { password?: string }) => {
    try {
      if (user.email.toLowerCase() !== this.user?.email) {
        await this.auth.currentUser?.updateEmail(user.email);
      }

      if (password) {
        await this.auth.currentUser?.updatePassword(password);
      }

      this.setUser(
        await this.updateUser({
          ...user,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        })
      );
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  logOut = async () => {
    try {
      if (this.user) {
        audit.logEvent("user_log_out", {
          id: this.user.id,
          email: this.user.email,
        });
        await this.clearUserLastActivity();
        await this.updateStatus(UserStatus.OFFLINE);
      }

      await this.auth.signOut();
      localStorage.removeItem("login_at");
      localStorage.removeItem("remember_me");
      localStorage.removeItem("fcmToken");
      this.setUser(undefined);
    } catch (error) {
      logError(error);
    } finally {
      window.location.reload();
    }
  };

  resendVerificationEmail = async () => {
    const user = this.auth.currentUser;

    if (!user) {
      throw new Error("auth/user-not-found");
    }

    try {
      await user.sendEmailVerification();
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  hasConfirmedEmail = async () => {
    if (this.auth.currentUser === null || this.user === null) {
      throw new Error("auth/user-not-found");
    }

    try {
      await this.auth.currentUser.reload();
      return this.auth.currentUser.emailVerified;
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  sendResetPassword = async (email: string) => {
    try {
      await this.auth.sendPasswordResetEmail(email);
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  reauthenticate = async (email: string, password: string) => {
    try {
      await this.auth.currentUser?.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(email, password)
      );
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  remove2Fa = async () => {
    if (!this.firebaseUser) return;

    try {
      const factors = multiFactor(this.firebaseUser).enrolledFactors;
      factors.forEach((f) => {
        multiFactor(this.firebaseUser!).unenroll(f.uid);
      });
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  set2FAResolver = (resolver?: firebase.auth.MultiFactorResolver) => {
    this.resolver = resolver;
  };

  checkPhoneNumberUnique = async (phoneNumber: string) => {
    const snapshot = await this.db
      .collection("users")
      .where("phoneNumber", "==", phoneNumber)
      .get();

    return snapshot.empty;
  };

  updateStatus = async (status: UserStatus) => {
    if (!this.user) {
      return;
    }

    const lastStatus = this.user?.status;

    this.setUser(
      await this.updateUser({
        ...this.user,
        status,
      })
    );

    const statusLog: UserStatusLog = {
      oldStatus: lastStatus,
      status: status,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    await this.db
      .collection("users")
      .doc(this.user?.id)
      .collection("statusLogs")
      .add(statusLog);

    await users.updateUserInChathub(this.user?.id);
  };

  updateAvatar = async (avatarUrl: string) => {
    if (!this.user) {
      return;
    }

    this.setUser(
      await this.updateUser({
        ...this.user,
        avatarUrl,
      })
    );
  };

  updatePermissions = async (permissions: UserPermissions) => {
    if (!this.user) {
      return;
    }

    this.setUser(
      await this.updateUser({
        ...this.user,
        permissions,
      })
    );
  };

  setFCMToken = async (token: string) => {
    if (!this.user) {
      return;
    }
    const device = detect.parse(navigator.userAgent);

    await this.db
      .collection("users")
      .doc(this.user.id)
      .collection("fcmTokens")
      .doc(token)
      .set({
        deviceType: device.device.type,
        deviceOSName: device.os.name,
        createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      });

    localStorage.setItem("fcmToken", token);
  };

  deleteFCMToken = async (token: string) => {
    if (!this.user) {
      return;
    }

    await this.db
      .collection("users")
      .doc(this.user.id)
      .collection("fcmTokens")
      .doc(token)
      .delete();

    localStorage.removeItem("fcmToken");
  };

  loginCompleted = async () => {
    if (localStorage.getItem("remember_me")) {
      localStorage.setItem("login_at", new Date().toISOString());
      settings.setAuthPersist(true);
    } else {
      settings.setAuthPersist(false);
    }
    localStorage.removeItem("fcmToken");
    await this.updateStatus(UserStatus.ONLINE);
  };

  private getUser = async (id: string): Promise<User | undefined> => {
    const ref = this.db.collection("users").doc(id);
    const snapshot = await ref.get();

    if (snapshot.exists) {
      ref.onSnapshot((doc) => {
        if (doc.exists) {
          const userData = doc.data();
          if (this.user) {
            this.user.status = userData?.status;
            this.user.permissions = userData?.permissions;
            this.setMyQueueCountInTitle();
          }
        }
      });
      return snapshot.data() as User;
    }

    return undefined;
  };

  private updateUser = async ({
    lastActivity,
    lastActivityOnBot,
    ...user
  }: User): Promise<User> => {
    user.email = user.email.toLowerCase();

    if (user.createdAt !== user.updatedAt) {
      delete (user as any).createdAt;
    }

    await this.db.collection("users").doc(user.id).set(user, { merge: true });

    return user;
  };

  private clearUserLastActivity = async () => {
    if (!this.user) {
      return;
    }

    await this.db.collection("users").doc(this.user.id).update({
      lastActivity: firebase.firestore.FieldValue.serverTimestamp(),
      lastActivityOnBot: -1,
    });
  };

  getBearerToken = async () => {
    return (
      (await this.auth.currentUser?.getIdToken()) ?? String(this.bearerToken)
    );
  };

  getWorkspacePermissions = (user?: User) => {
    if (!workspaces.selectedWorkspace) {
      return {};
    }

    if (!user) {
      user = this.user;
    }

    const workspacePermissions =
      user?.permissions?.perWorkspace?.[workspaces.selectedWorkspace.id];
    return workspacePermissions;
  };

  getPermissions = (user?: User) => {
    if (!workspaces.selectedFunction || !workspaces.selectedWorkspace) {
      return {};
    }

    if (!user) {
      user = this.user;
    }
    return user?.permissions?.perWorkspace?.[workspaces.selectedWorkspace.id]
      ?.perFunction?.[workspaces.selectedFunction.slug];
  };

  askAdminToJoinWorkspace = async (workspaceId: string) => {
    const user = this.user;

    if (!user) {
      return;
    }

    await workspaces.findWorkspace(workspaceId);

    if (user.workspaceIds?.includes(workspaceId)) {
      throw new Error("already_member");
    }

    await this.db
      .collection("users")
      .doc(user.id)
      .update({
        type: UserType.Collaborator,
        workspaceIds: firebaseApp.firestore.FieldValue.arrayUnion(workspaceId),
        permissions: {
          ...user.permissions,
          perWorkspace: {
            ...user.permissions?.perWorkspace,
            [workspaceId]: {
              userDeactivated: true,
            },
          },
        },
      });
  };

  private setInitialized = (initialized: boolean) => {
    this.initialized = initialized;
  };

  public setUser = (user?: User) => {
    this.user = user;
    if ([UserType.Root, UserType.Administrator].includes(user?.type!)) {
      this.userHasAdminAccess = true;
    }
  };

  public setMyQueueCountInTitle = () => {
    const myQueueCounts = this.getPermissions()?.chatCounts;
    document.title = myQueueCounts
      ? `(${myQueueCounts}) EDNA Platform`
      : "EDNA Platform";
  };
}

export default new AuthStore();
