import { useMemo } from "react";
import { EdnaBotId, ednaBotUser, EdnaChatType, UserStatus } from "../models";
import { useStore } from "../stores";

export type ChatInterface = {
  id: string;
  name: string;
  image: string;
  type: EdnaChatType;
  userStatus?: string;
  extras?: any;
};

const useTeammates = () => {
  const { selectedWorkspace, activeLiveChatCards } = useStore("workspaces");
  const { aITeams } = useStore("aITeams");
  const { users } = useStore("users");
  const { hubs } = useStore("hubs");
  const auth = useStore("auth");

  const chats: ChatInterface[] = useMemo(() => {
    return [
      ...(selectedWorkspace?.settings?.ednaBotEnabled
        ? [
            {
              id: ednaBotUser.id,
              name: ednaBotUser.name || "",
              image: ednaBotUser.avatarUrl || "/assets/bot.svg",
              type: EdnaChatType.BOT,
            },
          ]
        : []),
      ...aITeams.map((aITeam) => {
        const proxyAgentName = aITeam?.teamInfo?.agents?.find(
          (f: any) => f.proxy
        )?.name;
        return {
          id: String(aITeam.id),
          name:
            aITeam?.agentNames?.[proxyAgentName] ||
            proxyAgentName ||
            aITeam.name,
          image: aITeam?.teamInfo?.icon || "/assets/bot.svg",
          type: EdnaChatType.AI_TEAM,
          extras: {
            aIteamInfo: aITeam.teamInfo,
          },
        };
      }),
      ...(activeLiveChatCards.map((liveChatCard) => ({
        id: String(liveChatCard.id),
        name: liveChatCard.name || "",
        image:
          selectedWorkspace?.liveCardProfiles?.[liveChatCard.id] ??
          `https://ui-avatars.com/api/?name=${liveChatCard.name}&font-size=0.33`,
        type: EdnaChatType.CHANNEL,
      })) ?? []),
      ...(hubs?.map((hub) => ({
        id: String(hub.id),
        name: hub.name,
        image: `https://ui-avatars.com/api/?name=${hub.name}&background=random&font-size=0.4`,
        type: EdnaChatType.HUB,
        extras: {
          workspaceId: hub.workspaceId,
        },
      })) ?? []),
      ...(users || [])
        .filter((f) => f.id !== auth?.user?.id)
        .map((user) => ({
          id: user.id,
          name: user.name || "",
          image:
            user.id === EdnaBotId
              ? "/assets/edna-bot.jpeg"
              : user?.avatarUrl || "/assets/avatar.svg",
          type: EdnaChatType.SINGLE,
          userStatus: user.status,
        }))
        .sort((a, b) => {
          // Empty status should come last
          if (!a.userStatus && b.userStatus) {
            return 1; // Empty status should come after non-empty statuses
          } else if (a.userStatus && !b.userStatus) {
            return -1; // Non-empty status should come before empty statuses
          }

          if (
            a.userStatus === UserStatus.ONLINE &&
            b.userStatus !== UserStatus.ONLINE
          ) {
            return -1; // "ONLINE" should come before other statuses
          } else if (
            a.userStatus !== UserStatus.AWAY &&
            b.userStatus === UserStatus.AWAY
          ) {
            return 1; // AWAY statuses should come after "ONLINE"
          } else {
            return 0; // Keep the original order for other elements
          }
        }),
    ];
  }, [
    selectedWorkspace?.settings?.ednaBotEnabled,
    selectedWorkspace?.liveCardProfiles,
    aITeams,
    activeLiveChatCards,
    users,
    hubs,
    auth?.user?.id,
  ]);

  return { chats };
};

export default useTeammates;
